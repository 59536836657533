import { memo } from 'react'
import styled from 'styled-components'
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths'
import { QuadrantSelector } from '../input/quadrant/QuadrantSelector'
import { dateHelper, projectHelper } from '../../common/src/helpers'
import { PlusButton } from '../buttons/PlusButton'
import { useTranslation } from 'react-i18next'
import { useMobile } from '../../helpers/responsiveHelpers'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getPreferredMatrixViewMode } from '../../selectors/uiSelectors'
import { getRelativePathToMatrixBasedOnMode } from '../../helpers/routeHelper'
import { AddSquareFilled } from '@fluentui/react-icons'
import { intlFormatDistance } from 'date-fns'

export const PROJECT_CARD_MAX_WIDTH = 160

const Container = styled(Link)`
  flex: 1;
  box-sizing: border-box;
  max-width: ${1.5 * PROJECT_CARD_MAX_WIDTH}px;
  height: 7rem;
  text-decoration: none;

  background-image: linear-gradient(${p => p.theme.palette.always_primary} 33%, ${p => p.theme.palette.white} 33%);
  box-shadow: ${Depths.depth4};
  display: block;
  border-radius: 3px;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: pointer;
      box-shadow: inset 0 0 0 1px ${p => p.theme.palette.themePrimary};
    }
  }

  :active {
    background-image: linear-gradient(
      ${p => p.theme.palette.always_primary} 33%,
      ${p => p.theme.palette.neutralTertiaryAlt} 33%
    );
  }

  color: ${p => p.theme.palette.black};
`

const ProjectBasicCard = memo(({ title, subTitle, tooltip, icon, linkTo, ...rest }) => {
  const isMobilePlatform = useMobile()
  const minWidthFactor = isMobilePlatform ? 0.7 : 1.5
  const minWidth = PROJECT_CARD_MAX_WIDTH * minWidthFactor

  return (
    <Container to={linkTo} style={{ minWidth }} {...rest}>
      <div className="p-4">
        <div className="relative flex">{icon}</div>
        <div className="flex" title={title}>
          <span className="max-w-full truncate text-sm/8 font-semibold">{title}</span>
        </div>
        <small className="inline-block max-w-full truncate">{subTitle}</small>
      </div>
    </Container>
  )
})

export const ProjectCard = memo(({ project, ...rest }) => {
  const isMobilePlatform = useMobile()
  const mode = useSelector(getPreferredMatrixViewMode)
  const id = projectHelper.getIdd(project)
  const { t } = useTranslation()
  const name = projectHelper.getName(project)
  const timestamp = projectHelper.getTimestamp(project)
  const date = dateHelper.toDate(timestamp)
  const relativeTimeString = intlFormatDistance(date, new Date(), { locale: navigator.language })
  const modified = isMobilePlatform ? '' : t('general.modified') + ' '
  const subTitle = modified + relativeTimeString
  return (
    <ProjectBasicCard
      linkTo={getRelativePathToMatrixBasedOnMode(mode)(id)}
      title={name}
      tooltip={name}
      subTitle={subTitle}
      icon={<QuadrantSelector quadrant={0} size={32} project={project} readOnly />}
      {...rest}
    />
  )
})

const StyledPlusButton = styled(PlusButton).attrs(p => ({
  size: 32,
  circleColor: p.theme.palette.neutralLighterAlt,
}))``

export const NewProjectCard = memo(props => {
  const { t } = useTranslation()
  return (
    <ProjectBasicCard
      title={t('project.create_project')}
      subTitle={t('project.short_message_to_encourage_creation')}
      icon={<AddSquareFilled className="!text-3xl" />}
      {...props}
    >
      <StyledPlusButton />
    </ProjectBasicCard>
  )
})
