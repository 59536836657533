import { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

export const useComponentDidMount = f => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(f, [])
}
export const useMountDebugger = name => {
  useEffect(() => {
    console.log('mount ' + name)
    return () => {
      console.log('unmount ' + name)
    }
  }, [name])
}
export const useMergeState = initialState => {
  const [state, setState] = useState(initialState)
  const setMergedState = useCallback(
    value => {
      setState(prevState => {
        const newState = _.isFunction(value) ? value(prevState) : value
        return { ...prevState, ...newState }
      })
    },
    [setState]
  )
  return [state, setMergedState]
}
export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
