import { useAttentionNeededInSession } from '../common/src/hooks/itemHooks'
import { isInitializedRequestForPMResource } from '../common/src/selectors/requestStateSelectors'
import { useSelector } from 'react-redux'
import { PM_API_RESOURCES } from '../common/src/constants'

export const attentionNeededCountToString = (count = 0) => {
  if (!count) {
    return ''
  }
  return count > 999 ? '∞' : `${count}`
}

export const useAttentionNeeded = () => {
  const initialized = useSelector(s => isInitializedRequestForPMResource(s, PM_API_RESOURCES.ATTENTION_NEEDED))
  const items = useAttentionNeededInSession()
  const count = items.size
  return {
    initialized,
    items,
    countString: attentionNeededCountToString(items.size),
    count,
  }
}
