import { Text } from '@fluentui/react'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AI_SUPPORT_EMAIL, useFillNotesWithAI } from '../../hooks/aiHooks'
import { useDispatch, useSelector } from 'react-redux'
import { getAIMessageDismissed } from '../../selectors/uiSelectors'
import { dismissAIMessage as dismissAIMessageAction } from '../../actions/uiActions'
import { Button, Link, Popover, PopoverSurface, PopoverTrigger, Spinner } from '@fluentui/react-components'
import { Sparkle } from '../BundledIcons'
import { openPurchaseExperienceAction } from '../../utils/teams'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

export type FillWithAIButtonProps = {
  disabled?: React.ComponentProps<typeof Button>['disabled']
  itemName: string
  itemCurrentNotes?: string
  itemProjectName?: string
  itemProjectIdd?: number
  eventTrackingMode?: string
  updateNotes: (newNotes: string) => void
}

export const FillWithAIButton = ({
  disabled = false,
  itemName,
  itemProjectName,
  itemProjectIdd,
  itemCurrentNotes,
  eventTrackingMode,
  updateNotes,
}: FillWithAIButtonProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()

  const aiMessageDismissed = useSelector(getAIMessageDismissed)
  const doNotShowAgainAIMessage = () => dispatch(dismissAIMessageAction())

  const [canShowReviewMessage, setCanShowReviewMessage] = useState(false)
  const [canShowError, setCanShowError] = useState(false)

  const _updateNotes = (newNotes: string) => {
    updateNotes(newNotes)
    setCanShowReviewMessage(true)
  }

  const { error, loading, handleFillWithAI } = useFillNotesWithAI({
    name: itemName,
    projectName: itemProjectName,
    projectIdd: itemProjectIdd,
    currentNotes: itemCurrentNotes,
    eventTrackingMode,
    updateNotes: _updateNotes,
  })

  disabled = disabled || loading

  const handleClick = async () => {
    setCanShowError(true)
    await handleFillWithAI()
  }

  const onDismiss = () => {
    if (error) {
      setCanShowError(false)
    } else {
      setCanShowReviewMessage(false)
    }
  }

  const onClickUpgrade = () => {
    dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.AI_NOTES))
  }
  const showUpgradeButton = error?.limitExceeded && shouldDisplayUpgrade

  return (
    <Popover
      open={(error && canShowError) || (canShowReviewMessage && !aiMessageDismissed)}
      onOpenChange={(e, data) => {
        if (!data.open) {
          onDismiss()
        }
      }}
      withArrow
    >
      <PopoverTrigger>
        <Button icon={<Sparkle />} disabled={disabled} onClick={handleClick} appearance="primary">
          {t('ai.fill_with_ai')}
          {loading && <Spinner size="tiny" className="ml-3" />}
        </Button>
      </PopoverTrigger>
      {error ? (
        <AICallout
          title={error.title}
          message={error.message}
          onDismiss={onDismiss}
          additionalButtons={
            showUpgradeButton ? (
              <Button appearance="primary" onClick={onClickUpgrade}>
                {t('general.upgrade')}
              </Button>
            ) : undefined
          }
        />
      ) : (
        <AICallout
          onDismiss={onDismiss}
          title={t('ai.review_message.title')}
          message={
            <Trans
              t={t}
              i18nKey="ai.review_message.message"
              components={{ mylink: <Link href={'mailto:' + AI_SUPPORT_EMAIL} target="_blank" /> }}
            />
          }
        >
          <Text variant="medium" className="mt-3">
            <Link
              as="button"
              onClick={() => {
                setCanShowReviewMessage(false)
                doNotShowAgainAIMessage()
              }}
            >
              {t('ai.review_message.do_not_show_again')}
            </Link>
          </Text>
        </AICallout>
      )}
    </Popover>
  )
}

type AICalloutProps = {
  title: string
  message: React.ReactNode
  children?: React.ReactNode
  onDismiss: () => void
  additionalButtons?: React.ReactNode
}

const AICallout = ({ title, message, children, onDismiss, additionalButtons }: AICalloutProps) => {
  const { t } = useTranslation()
  return (
    <PopoverSurface className="flex w-72 flex-col px-4 py-2">
      <Text className="mb-3" variant="large">
        {title}
      </Text>
      <Text variant="medium">{message}</Text>
      {children}
      <div className="mt-4 flex gap-2 self-end">
        {additionalButtons}
        <Button onClick={onDismiss}>{t('general.close')}</Button>
      </div>
    </PopoverSurface>
  )
}
