import { useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '../../common/src/helpers'
import { checkOwnershipByMode } from './checkOwnershipByMode'
import { useMemo } from 'react'
import { mapValuesToJsArray } from '../../common/src/helpers/immutableHelpers'
import _ from 'lodash'

const sortItemByLastModified = item => -itemHelper.getLastModifiedTimestamp(item)

export const useMyItems = mode => {
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const meEmail = useSelector(stateHelper.getMeEmail)
  const checkOwnership = checkOwnershipByMode[mode]
  const filteredItems = allItems.filter(i => {
    return checkOwnership(i, meEmail) && itemHelper.getState(i) === itemHelper.STATE.UNFINISHED
  })
  const sorted = useMemo(() => {
    return mapValuesToJsArray(filteredItems, _.identity, void 0, sortItemByLastModified)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredItems.size]) //this is a trick to avoid jumpy list effect
  return _.map(sorted, item => {
    const id = itemHelper.getId(item)
    const newItem = filteredItems.find(iterItem => itemHelper.getId(iterItem) === id)
    return newItem || item
  }).slice(0, 30)
}
