import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths'

const Circle = styled.svg`
  width: ${p => `${p.size}px`};
  height: ${p => `${p.size}px`};
  border-radius: ${p => `${p.size}px`};
  box-shadow: ${Depths.depth4};
  transition: opacity 0.33s;

  & .add {
    fill: ${p => p.addColor || p.theme.palette.themePrimary};
  }

  & .circle {
    fill: ${p => p.circleColor || p.theme.palette.white};
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover .circle {
      fill: ${p => p.hoveredCircleColor || p.theme.palette.neutralLight};
    }
  }
`

export const COMPONENT = ({ size = 14, addColor, circleColor, hoveredCircleColor, ...rest }) => {
  return (
    <Circle
      viewBox="0 0 512 512"
      size={size}
      addColor={addColor}
      circleColor={circleColor}
      hoveredCircleColor={hoveredCircleColor}
      {...rest}
    >
      <circle className="circle" cx="256" cy="256" r="256" />
      <polygon
        className="add"
        points="276 236 276 81 236 81 236 236 81 236 81 276 236 276 236 431 276 431 276 276 431 276 431 236 276 236"
      />
    </Circle>
  )
}

export const PlusButton = React.memo(COMPONENT)

PlusButton.propTypes = {
  size: PropTypes.number,
  addColor: PropTypes.string,
  circleColor: PropTypes.string,
  hoveredCircleColor: PropTypes.string,
}
