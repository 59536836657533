import {
  add,
  addHours,
  addYears,
  differenceInBusinessDays,
  endOfDay,
  format,
  formatDurationWithOptions,
  getUnixTime,
  intlFormat,
  isSameDay,
  isWeekend,
  isWithinInterval,
  set,
  setDay,
  startOfDay,
  startOfISOWeek,
} from 'date-fns/fp'
import type { Locale } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'

export const datefns = {
  add,
  addHours,
  addYears,
  differenceInBusinessDays,
  endOfDay,
  format,
  getUnixTime,
  isSameDay,
  isWeekend,
  isWithinInterval,
  set,
  setDay,
  startOfDay,
  startOfISOWeek,
}

export const useDateFnsLocale = () => {
  const [locale, setLocale] = useState<Locale | undefined>(undefined)
  const { i18n } = useTranslation()
  useEffect(() => {
    if (i18n.language.startsWith('es')) {
      import('date-fns/locale/es').then(pkg => setLocale(pkg.es))
    } else {
      setLocale(undefined)
    }
  }, [i18n.language])
  return locale
}

export const useFormatDuration = () => {
  const locale = useDateFnsLocale()
  return useMemo(() => formatDurationWithOptions({ locale }), [locale])
}

export type Format = 'ShortenedNormal' | 'ShortenedAllDay' | 'Normal' | 'LongNormal'
interface FormatOptions {
  timeZone?: string
  hour12?: boolean
  formatMatcher?: 'basic' | 'best fit'
  timeZoneName?: 'short' | 'long'
  second?: 'numeric' | '2-digit'
  minute?: 'numeric' | '2-digit'
  hour?: 'numeric' | '2-digit'
  day?: 'numeric' | '2-digit'
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long'
  year?: 'numeric' | '2-digit'
  era?: 'narrow' | 'short' | 'long'
  weekday?: 'narrow' | 'short' | 'long'
  localeMatcher?: 'lookup' | 'best fit'
}

const FormatMap: Record<Format, FormatOptions> = {
  ShortenedAllDay: {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
    hour: undefined,
    minute: undefined,
    second: undefined,
  },
  ShortenedNormal: {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    second: undefined,
  },
  LongNormal: {
    year: 'numeric',
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    second: undefined,
  },
  Normal: {
    year: 'numeric',
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    second: undefined,
  },
} as const

type FormatParam = Format | FormatOptions

const getDefaultLocale = () => navigator.language

export const formatDateWithLocale = (locale?: string) => (format: FormatParam) => (date: Date | string) =>
  intlFormat({ locale: locale ?? getDefaultLocale() })(typeof format === 'string' ? FormatMap[format] : format)(date)

export const formatDate = formatDateWithLocale(navigator.language)

type DateFormatter = (d: Date) => string

interface CalendarOptions {
  sameDay: DateFormatter
  nextDay: DateFormatter
  nextWeek: DateFormatter
  lastDay: DateFormatter
  lastWeek: DateFormatter
  sameElse: DateFormatter
}

export const calendar =
  (options: CalendarOptions) =>
  (ref: Date) =>
  (date: Date): string => {
    if (isSameDay(ref)(date)) {
      return options.sameDay(date)
    }
    if (isSameDay(add({ days: 1 })(ref))(date)) {
      return options.nextDay(date)
    }
    if (date > ref && date < add({ days: 7 })(ref)) {
      return options.nextWeek(date)
    }
    if (isSameDay(add({ days: -1 })(ref))(date)) {
      return options.lastDay(date)
    }
    if (date < ref && date > add({ days: -7 })(ref)) {
      return options.lastWeek(date)
    }
    return options.sameElse(date)
  }
