import React, { memo, useCallback } from 'react'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { Matrix } from '../../components/matrix/Matrix'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { MainLayout } from '../../components/layout/MainLayout'
import { ProjectLevelTopBar } from '../project/ProjectLevelTopBar'
import { OnboardingView } from '../../components/onboarding/OnboardingView'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { itemHelper } from '../../common/src/helpers'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'

const _ProjectMatrixView = ({
  project,
  items,
  selectedItemId,
  sortFn,
  loading,
  projectSelectorEnabled = true,
  readOnly = false,
  matrixPreviewMode = false,
  showOwner = true,
}) => {
  const narrowWidth = useNarrowWidth()

  const onClickItem = useCallback(
    item => {
      if (matrixPreviewMode) {
        return
      }
      const serverID = itemHelper.getId(item)
      const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, serverID)
      linkSubject.next({ urlData, source: SOURCES.MATRIX })
    },
    [matrixPreviewMode]
  )

  const showOnboardingView = !narrowWidth && !readOnly

  const main = (
    <>
      <Matrix
        items={items}
        onClickItem={onClickItem}
        project={project}
        selectedItemId={selectedItemId}
        sortFn={sortFn}
        readOnly={readOnly}
        loading={loading}
        showOwner={showOwner}
      />
      {showOnboardingView && <OnboardingView />}
    </>
  )
  const detail = <RightDetailView readOnly={readOnly} />
  const topBar = (
    <ProjectLevelTopBar
      getNavigationURLForProjectId={getRelativeURLKeepingQuerySearch.matrixForProjectId}
      key={'ProjectMatrixViewTopBar'}
      project={project}
      projectSelectorEnabled={projectSelectorEnabled}
      readOnly={readOnly}
      items={items}
    />
  )
  let subViews = { main }
  if (!matrixPreviewMode) {
    subViews = {
      ...subViews,
      rightSide: detail,
      topBar,
    }
  }

  return <MainLayout {...subViews} />
}

export const ProjectMatrixView = memo(_ProjectMatrixView)
