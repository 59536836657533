import React, { memo, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useToastController } from '@fluentui/react-components'
import { MeetingDetail } from './MeetingDetail'
import { MeetingLayout } from '../../components/layout/MeetingLayout'
import { useFetch } from '../../common/src/hooks/networkingHooks'
import { getMatchParameterInteger } from '../../common/src/utils'
import { itemHelper, meetingHelper, userHelper } from '../../common/src/helpers'
import { ElementTypes as NotesEditorElementTypes } from '../../components/notesEditor/NotesEditorTypes'
import { emptyValue as notesEditorEmptyValue } from '../../components/notesEditor/NotesEditorUtils'
import { getAvatarURIForEmail, getItemLink } from '../../common/src/constants'
import { getMeeting, getMeetingItems, getNearestMeeting } from '../../common/src/actions/meetingsAPI'
import { manageLink } from '../../utils/externalLinkHandler'
import { ItemPanel } from '../../components/panels/item/ItemPanel'
import { selectMeeting } from '../../actions/uiActions'
import { Mobile } from '../../helpers/responsiveHelpers'
import { SearchItemsPanel } from '../../components/searchPanel/SearchItemsPanel'
import { SearchItems } from '../../components/searchPanel/SearchItems'
import { useGetUserByEmail } from '../../common/src/hooks/usersHooks'
import { copyToClipboard } from '../../common/src/helpers/clipboardUtils'
import { useShowToastDidCopyLinkToClipboard } from '../../hooks/clipboardHooks'
import { useEventListener } from '../../common/src/hooks/eventsHooks'
import { meetingsSelectors, teamsSelectors } from '../../common/src/selectors'
import { putMeetingIdForTab } from '../../common/src/actions/teamsTabAPI'
import { useDebugHandler } from '../../hooks/useDebugToast'
import { useIsInMeeting, useTabInfo } from '../../hooks/msTeamsHooks'
import { resourceURIParser } from '../../common/src/helpers/URLHelper'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { usePrevious } from '../../common/src/hooks/enhancedHooks'
import { MainHeader } from '../../components/layout/MainHeader'
import { queryParamsHelper } from '../../helpers'
import { useTranslation } from 'react-i18next'
import { FluentToast } from '../../components/toast/FluentToast'

const useAssignMeetingToTab = ({ meeting, dispatch, teamsContext }) => {
  const { tab } = useTabInfo(teamsContext)
  useEffect(() => {
    const associateMeeting = tab?.get?.('pm_meeting')
    const tabId = tab?.get?.('id')
    const { id: associatedMeetingId } = resourceURIParser(associateMeeting) || {}
    const meetingId = meetingHelper.getID(meeting)
    if (meetingId && meetingId !== associatedMeetingId && tabId) {
      dispatch(
        putMeetingIdForTab({
          id: tabId,
          meetingId,
        })
      )
    }
  }, [dispatch, meeting, tab])
}

export const MeetingView = memo(({ history, location, match, ...rest }) => {
  const dispatch = useDispatch()
  const [state, localDispatch] = useReducer(reducer, createInitialState())
  const { notes, notesNodes, isOpenItemPanel, isOpenSearchItemsPanel } = state
  const notesEditorRef = useRef(null)
  const showToastAddingItem = useShowToastAddingItem()
  const teamsContext = useSelector(teamsSelectors.getRawContext)
  const inMeeting = useIsInMeeting()
  const narrow = useNarrowWidth()

  const inTeams = queryParamsHelper.isEmbeddedOnTeams()
  const wasNavigated = queryParamsHelper.wasNavigated()
  // inside Teams for 1st navigation we get the nearest meeting not the meeting in url
  const shouldSuggestMeeting = !!inTeams && !wasNavigated
  const meetingID = getMatchParameterInteger(match, 'meetingid')
  const meetingSelector = shouldSuggestMeeting
    ? meetingsSelectors.getSelectedMeeting
    : meetingsSelectors.getMeeting(meetingID)
  const meeting = useSelector(meetingSelector)
  const meetingTitle = meetingHelper.getTitle(meeting)

  React.useLayoutEffect(() => {
    dispatch(selectMeeting(meetingID))
  }, [dispatch, meetingID])

  useDebugHandler({ meeting, teamsContext })
  useAssignMeetingToTab({ meeting, dispatch, teamsContext })

  const fetchMeeting = useCallback(() => {
    if (!meetingID) {
      return Promise.reject()
    }
    const getBestMeeting = shouldSuggestMeeting ? getNearestMeeting : getMeeting
    return dispatch(
      getBestMeeting(meetingID, {
        show_serie: 1,
      })
    )
  }, [dispatch, meetingID, shouldSuggestMeeting])

  const { loading, error: errorFetching } = useFetch(fetchMeeting)

  useEffect(() => {
    if (meetingID && meeting) {
      localDispatch({ type: ACTION_TYPE.INIT, payload: { meeting } })
    }
  }, [meetingID, meeting])

  const pendingRef = useRef(false)
  const updateNotes = useCallback(
    async notes => {
      if (!meetingID) {
        pendingRef.current = false
        return
      }
      //await dispatch(patchMeeting(meetingID, fromJS({ notes })))
      pendingRef.current = false
    },
    [meetingID]
  )
  const debouncedUpdateNotes = useMemo(() => _.debounce(updateNotes, 2500, { trailing: true }), [updateNotes])
  const debounceNotes = useCallback(
    notes => {
      pendingRef.current = true
      debouncedUpdateNotes(notes)
    },
    [debouncedUpdateNotes]
  )
  const flushNotes = useCallback(() => {
    debouncedUpdateNotes.flush()
  }, [debouncedUpdateNotes])

  const prevNotes = usePrevious(notes)
  useEffect(() => {
    if (prevNotes && notes && prevNotes !== notes) {
      pendingRef.current = true
      debounceNotes(notes)
    }
  }, [prevNotes, notes, debounceNotes])

  //console.log('NotesNodes: ', notesNodes)

  const onNavigateToMeeting = useCallback(
    id => {
      const url = getRelativeURLKeepingQuerySearch.meeting(meetingID, id)
      const search = queryParamsHelper.getSearchParamsWithNavigated()
      const newUrl = { ...url, search }
      history.push(newUrl)
    },
    [history, meetingID]
  )
  const onChangeNotes = useCallback(value => {
    localDispatch({ type: ACTION_TYPE.SET_NOTES, payload: value })
  }, [])
  const onBlurNodes = useCallback(() => {
    flushNotes()
  }, [flushNotes])

  const showItemPanel = useCallback(() => {
    localDispatch({ type: ACTION_TYPE.SHOW_ITEM_PANEL })
  }, [])
  const hideItemPanel = useCallback(() => {
    localDispatch({ type: ACTION_TYPE.HIDE_ITEM_PANEL })
  }, [])
  const showSearchItemsPanel = useCallback(() => {
    localDispatch({ type: ACTION_TYPE.SHOW_SEARCH_ITEMS_PANEL })
  }, [])
  const hideSearchItemsPanel = useCallback(ev => {
    localDispatch({ type: ACTION_TYPE.HIDE_SEARCH_ITEMS_PANEL })
  }, [])

  const getElementForItem = useGetElementForItem()
  const onAddItem = useCallback(
    item => {
      if (!notesEditorRef.current) {
        return
      }
      const notesEditor = notesEditorRef.current
      const element = getElementForItem(item)
      if (notesEditor.addElement(element)) {
        showToastAddingItem(item)
      }
    },
    [getElementForItem, showToastAddingItem]
  )

  const onSelectItem = useCallback(
    item => {
      const id = itemHelper.getId(item)
      history.push(getRelativeURLKeepingQuerySearch.meeting(meetingID, id))
      showItemPanel()
    },
    [history, showItemPanel, meetingID]
  )

  const onOpenLink = useMemo(() => {
    return manageLink(({ id }) => {
      history.push(getRelativeURLKeepingQuerySearch.meeting(meetingID, id))
      showItemPanel()
    })
  }, [history, showItemPanel, meetingID])

  const showToastDidCopyLinkToClipboard = useShowToastDidCopyLinkToClipboard()
  const onCopyLink = useCallback(
    url => {
      copyToClipboard(url)
      showToastDidCopyLinkToClipboard()
    },
    [showToastDidCopyLinkToClipboard]
  )

  const beforeUnload = useCallback(ev => {
    if (pendingRef.current) {
      ev.preventDefault()
      ev.stopPropagation()
      ev.returnValue = ''
      return null
    }
  }, [])
  useEventListener('beforeunload', beforeUnload)

  const fetchMeetingItems = useCallback(() => getMeetingItems(meetingID), [meetingID])
  const getMeetingItemsFromState = useMemo(
    () => _.partial(meetingsSelectors.getMeetingItems, _, meetingID),
    [meetingID]
  )

  const meetingDetail = (
    <MeetingDetail
      meeting={meeting}
      notes={notesNodes || notesEditorEmptyValue}
      onChangeNotes={onChangeNotes}
      onBlurNotes={onBlurNodes}
      onOpenLink={onOpenLink}
      onCopyLink={onCopyLink}
      onSearch={showSearchItemsPanel}
      onAddItem={onAddItem}
      onNavigateToMeeting={onNavigateToMeeting}
      loading={loading}
      error={errorFetching}
      notesEditorRef={notesEditorRef}
    />
  )

  const rightSide =
    narrow || errorFetching ? null : (
      <SearchItems
        onSelectItem={onSelectItem}
        onAddItem={onAddItem}
        getInitialItems={fetchMeetingItems}
        getInitialItemsFromState={getMeetingItemsFromState}
      />
    )

  const onClickLogo = () => {
    if (!inTeams) {
      history.push(getRelativeURLKeepingQuerySearch.index())
    }
  }

  const header = inMeeting ? null : <MainHeader title={meetingTitle} onClickLogo={onClickLogo} />
  return (
    <>
      <MeetingLayout header={header} main={meetingDetail} rightSide={rightSide} />
      <Mobile>
        <SearchItemsPanel
          isOpen={isOpenSearchItemsPanel}
          onDismiss={hideSearchItemsPanel}
          onAddItem={onAddItem}
          getInitialItems={fetchMeetingItems}
          getInitialItemsFromState={getMeetingItemsFromState}
        />
      </Mobile>
      <ItemPanel isOpen={isOpenItemPanel} onDismiss={hideItemPanel} />
    </>
  )
})

const createInitialState = () => {
  return {
    meetingID: null,
    notes: null,
    notesNodes: null,
    isOpenItemPanel: false,
    isOpenSearchItemsPanel: false,
  }
}

const ACTION_TYPE = {
  INIT: 'INIT',
  SET_NOTES: 'SET_NOTES',

  SHOW_ITEM_PANEL: 'SHOW_ITEM_PANEL',
  HIDE_ITEM_PANEL: 'HIDE_ITEM_PANEL',
  SHOW_SEARCH_ITEMS_PANEL: 'SHOW_SEARCH_ITEMS_PANEL',
  HIDE_SEARCH_ITEMS_PANEL: 'HIDE_SEARCH_ITEMS_PANEL',
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.INIT:
      {
        const { meeting } = payload
        const meetingID = meetingHelper.getID(meeting)
        if (state.meetingID === meetingID) {
          return state
        }
        state.meetingID = meetingID

        let notesNodes = meetingHelper.getNotes(meeting)
        if (!_.isArray(notesNodes) || _.isEmpty(notesNodes)) {
          notesNodes = notesEditorEmptyValue
        }
        state.notes = JSON.stringify(notesNodes)
        state.notesNodes = notesNodes
      }
      break
    case ACTION_TYPE.SET_NOTES:
      {
        const notesNodes = action.payload
        state.notes = JSON.stringify(notesNodes)
        state.notesNodes = notesNodes
      }
      break
    case ACTION_TYPE.SHOW_ITEM_PANEL:
      state.isOpenItemPanel = true
      break
    case ACTION_TYPE.HIDE_ITEM_PANEL:
      state.isOpenItemPanel = false
      break
    case ACTION_TYPE.SHOW_SEARCH_ITEMS_PANEL:
      state.isOpenSearchItemsPanel = true
      break
    case ACTION_TYPE.HIDE_SEARCH_ITEMS_PANEL:
      state.isOpenSearchItemsPanel = false
      break
    default:
      break
  }
  return { ...state }
}

const useShowToastAddingItem = () => {
  const { dispatchToast } = useToastController()
  const { t } = useTranslation()
  return useCallback(
    item => {
      const name = itemHelper.getName(item)
      dispatchToast(<FluentToast body={name}>{t('meeting.item_added')}</FluentToast>, { intent: 'success' })
    },
    [dispatchToast, t]
  )
}

const useGetElementForItem = () => {
  const getUserByEmail = useGetUserByEmail()

  return useCallback(
    item => {
      const itemID = itemHelper.getId(item)
      const email = itemHelper.getOwnerUsername(item)
      const user = getUserByEmail(email)
      const link = getItemLink(itemID)
      return {
        type: NotesEditorElementTypes.checkListItem,
        id: itemID,
        url: link,
        user: {
          email: email,
          fullName: userHelper.getFullName(user),
          imageUrl: getAvatarURIForEmail(email),
        },
        children: [{ text: itemHelper.getName(item) }],
      }
    },
    [getUserByEmail]
  )
}
