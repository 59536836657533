import _ from 'lodash'
import { ProjectListFiltersPanel } from '../views/filters/ProjectListFiltersPanel'
import { ProjectMatrixFiltersPanel } from '../views/filters/ProjectMatrixFiltersPanel'
import { FILTER_REDUCER_KEYS } from '../common/src/reducers/filtersKeys'

export const PROJECT_MODE = {
  MATRIX: 'matrix',
  LIST: 'list',
  CALENDAR: 'calendar',
  FEED: 'feed',
  GANTT: 'gantt',
  REPORTS: 'reports',
}

const SUPPORT_GLOBAL = [PROJECT_MODE.FEED]
export const canGlobalMode = mode => _.find(SUPPORT_GLOBAL, m => m === mode)

export const projectModesProps = t => {
  return [
    {
      mode: PROJECT_MODE.MATRIX,
      text: t('project_mode.matrix_view'),
      iconName: 'GridViewMedium',
      readOnly: true,
    },
    {
      mode: PROJECT_MODE.LIST,
      text: t('project_mode.list_view'),
      iconName: 'CheckList',
      readOnly: true,
    },
    {
      mode: PROJECT_MODE.CALENDAR,
      text: t('project_mode.calendar_view'),
      iconName: 'Calendar',
      readOnly: true,
    },
    {
      mode: PROJECT_MODE.GANTT,
      text: t('project_mode.gantt_view'),
      iconName: 'TimelineProgress',
      readOnly: true,
    },
    {
      mode: PROJECT_MODE.FEED,
      text: t('project_mode.feed_view'),
      iconName: 'ActivityFeed',
    },
    {
      mode: PROJECT_MODE.REPORTS,
      text: t('project_mode.reports_view'),
      iconName: 'AreaChart',
    },
  ]
}

export const projectModeToPathMode = m => `/${m}/`
export const pathProjectModeToMode = pm => _.trim(pm, '/')
export const PROJECT_MODES = _.values(PROJECT_MODE)
export const PATH_PROJECT_MODES = _.map(PROJECT_MODES, projectModeToPathMode)

export const getProjectModeFromPathname = pathname => _.find(PROJECT_MODES, m => _.includes(pathname, m))
export const getPathProjectModeFromPathname = pathname => _.find(PATH_PROJECT_MODES, m => _.includes(pathname, m))

export const getFiltersTypeByMode = projectMode =>
  projectMode === PROJECT_MODE.LIST ? FILTER_REDUCER_KEYS.PROJECT_LIST : FILTER_REDUCER_KEYS.PROJECT_MATRIX
export const getFiltersPanelByMode = mode => {
  if (mode === PROJECT_MODE.LIST) {
    return ProjectListFiltersPanel
  }
  return ProjectMatrixFiltersPanel
}
