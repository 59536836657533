import React, { type ReactNode } from 'react'
import { cn } from '@appfluence/classnames'
import { ChatMessage, ChatMyMessage, type ChatMyMessageProps } from '@fluentui-contrib/react-chat'
import { Avatar, Button } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import type { CommentWithReadReceipts, ReadReceipt } from '@/types/comment'
import { isSameDay } from 'date-fns'
import { useTextCommentForJson, sanitize, isCommentFromVisitor, isEditedComment, useIsEditableComment } from './utils'
import { NotesDiffDialog } from './NotesDiffDialog'
import { ImageMessage } from './ImageMessage'
import { useTranslation } from 'react-i18next'
import {
  CheckmarkCircle16Regular,
  Circle16Regular,
  EditFilled,
  Eye16Filled,
  Warning16Filled,
} from '@fluentui/react-icons'
import { Edit } from '../BundledIcons'
import type { MutationStatus } from '@tanstack/react-query'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { Option } from '../../types'
import { isElectronApp } from '../../integrations/electron'
import { useDownloadFiles } from '../../hooks/filesHooks'
import type { Follower } from '../../types/item'
import { useMe } from '../../common/src/hooks/usersHooks'
import { userHelper } from '../../common/src/helpers'
import type { List, Map } from 'immutable'
import type { FileJSONPayload } from '@/types/comment'

type Comment = CommentWithReadReceipts & { type: 'comment' }

export type PMChatMessageProps = {
  comment: Partial<Comment> & Pick<Comment, 'timestamp' | 'text'>
  previousComment?: Comment
  mine: boolean
  compact?: boolean
  sendStatus?: 'sending' | 'failed'
  startEditing?: (commentId: number, startText: string) => void
  optimisticEditCommentText?: string
  optimisticEditCommentStatus?: MutationStatus
  itemFollowers?: List<Map<keyof Follower, string>>
}

type ChatMessageProps = {
  className?: string
} & React.ComponentProps<typeof ChatMessage>
type ChatMessageComponent = React.FC<ChatMessageProps>

const withLineBreaks = (text?: string) => {
  if (text === undefined) return text
  const lines = text.split('\n')
  return lines.reduce((acc, line, i) => {
    acc.push(line)
    if (i < lines.length - 1) {
      acc.push(<br key={i} />)
    }
    return acc
  }, [] as Array<ReactNode>)
}

export const PMChatMessage = ({
  comment,
  previousComment,
  mine,
  compact = true,
  sendStatus,
  startEditing,
  optimisticEditCommentText,
  optimisticEditCommentStatus,
  itemFollowers,
}: PMChatMessageProps) => {
  const narrow = useNarrowWidth()
  const { t } = useTranslation()
  const Component = (mine ? ChatMyMessage : ChatMessage) as ChatMessageComponent
  const date = new Date(Date.parse(`${comment.timestamp}Z`))
  const previousDate = previousComment ? new Date(Date.parse(`${previousComment.timestamp}Z`)) : undefined
  const sameDay = previousDate && isSameDay(date, previousDate)
  const showDetails =
    !previousDate ||
    !sameDay ||
    previousComment?.author !== comment.author ||
    previousComment?.author_email !== comment.author_email ||
    date.getTime() - previousDate.getTime() > 1000 * 60 * 2
  const dateInLastWeek = Math.abs(date.getTime() - Date.now()) < 1000 * 60 * 60 * 24 * 7
  const dateInThisYear = date.getFullYear() === new Date().getFullYear()
  const dateIsToday = isSameDay(date, new Date())
  const { commonDownload } = useDownloadFiles()

  const isVisitorComment = isCommentFromVisitor(comment)
  if (isVisitorComment) {
    comment.author_email = comment.json.visitor_email
    comment.author_fullname = comment.json.visitor_name
  }
  const username = isVisitorComment
    ? `${comment.author_fullname} (${comment.author_email} , visitor)`
    : comment.author_fullname || comment.author_email

  const text = useTextCommentForJson(comment.json) ?? comment.text

  const isImage = comment && !!comment.height && !!comment.width && comment.text.indexOf('uploaded image') === 0
  const asAutomatic = comment.is_automatic && !isImage
  const notesDiffHTML = comment.json && 'notesDiff' in comment.json ? comment.json.notesDiff : null
  const isEdited = isEditedComment(comment)
  const updatedDate = isEdited ? new Date(Date.parse(comment.json.updated_at)) : undefined

  const myMessageStatusProps = useMyMessageStatusProps(
    mine && !asAutomatic,
    sendStatus,
    comment.readReceipts,
    itemFollowers
  )
  const isEditableComment = useIsEditableComment()
  const now = new Date()

  const onClickComment = (evt: React.MouseEvent) => {
    if (!isElectronApp()) {
      return
    }
    const json = comment.json as Option<FileJSONPayload>
    const isFile = json?.type === 'file'
    if (!isFile) {
      return
    }
    const isEML = json.content_type === 'message/rfc822'
    if (!isEML) {
      return
    }
    const element = evt.target as Option<HTMLElement>
    const tagName = element?.tagName
    if (tagName !== 'A') {
      return
    }
    const filename = element!.innerText
    const url = element!.getAttribute('href')
    evt.stopPropagation()
    evt.preventDefault()
    commonDownload([
      {
        filename,
        url,
      },
    ])
  }

  return (
    <>
      {!sameDay && (
        <div className="mb-1 mt-5 flex justify-center text-xs text-gray-800 dark:text-gray-200">
          {dateIsToday
            ? t('date.today')
            : date.toLocaleString(
                undefined,
                dateInLastWeek
                  ? { weekday: 'long' }
                  : dateInThisYear
                    ? { day: 'numeric', month: 'long' }
                    : { day: 'numeric', month: 'numeric', year: 'numeric' }
              )}
        </div>
      )}
      <Component
        {...(Component === ChatMyMessage && !asAutomatic && myMessageStatusProps)}
        body={{
          className: cn(
            '!py-2',
            asAutomatic && '!bg-transparent border-[var(--colorNeutralBackground3)] border border-solid',
            compact && !mine && '!max-w-full'
          ),
        }}
        className={cn(
          showDetails ? '!pt-2' : '!pt-0.5',
          compact && mine && '!ml-5',
          compact && !mine && (showDetails ? '!ml-2' : '!ml-10'),
          mine && 'group relative'
        )}
        author={
          showDetails ? (
            <span className="font-[inherit]" title={comment.author_email}>
              {username}
            </span>
          ) : undefined
        }
        avatar={
          showDetails
            ? {
                className: '!min-w-[24px]',
                children: (
                  <Avatar
                    size={24}
                    name={username}
                    image={{ src: comment.author_avatar }}
                    title={`${comment.author_fullname} (${comment.author_email})`}
                  />
                ),
              }
            : undefined
        }
        timestamp={
          showDetails ? (
            <span className="text-inherit" title={date.toLocaleString()}>
              {date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })}
            </span>
          ) : undefined
        }
        details={
          isEdited || optimisticEditCommentStatus === 'pending' || optimisticEditCommentStatus === 'error' ? (
            <span
              className={cn('flex text-inherit', optimisticEditCommentStatus === 'error' && 'text-pm-negative')}
              title={
                optimisticEditCommentStatus === 'pending'
                  ? t('item_chat.editing')
                  : optimisticEditCommentStatus === 'error'
                    ? t('item_chat.edit_failed')
                    : isEdited
                      ? t('item_chat.comment_edited_tooltip', {
                          date: updatedDate?.toLocaleString(),
                          original_text: comment.json.previous_text,
                        })
                      : undefined
              }
            >
              {optimisticEditCommentStatus === 'error' ? <Warning16Filled /> : <EditFilled />}
            </span>
          ) : undefined
        }
      >
        {!isImage && !notesDiffHTML && (
          <span
            className={cn(
              '!text-[13px] [&_a]:text-pm-theme-primary [&_a]:no-underline',
              comment.is_automatic && 'italic text-gray-600 dark:text-gray-300',
              comment.is_command && 'text-pm-affirmative'
            )}
            onClickCapture={onClickComment}
            dangerouslySetInnerHTML={
              optimisticEditCommentStatus !== 'pending' && !sendStatus ? { __html: sanitize(text) } : undefined
            }
          >
            {withLineBreaks(
              optimisticEditCommentStatus === 'pending' ? optimisticEditCommentText : sendStatus ? text : undefined
            )}
          </span>
        )}
        {isImage && <ImageMessage comment={comment} />}
        {notesDiffHTML && (
          <span className="text-[13px] italic text-gray-600 dark:text-gray-300">
            {t('item_chat.notes_changed')} <NotesDiffDialog notesDiffHTML={notesDiffHTML} />
          </span>
        )}
        {isEditableComment(comment, now) && (
          <Tooltip content={t('item_chat.edit_button_tooltip')} relationship="label">
            <Button
              appearance="subtle"
              size="small"
              className={cn('!absolute !-left-6 !top-1', !narrow && 'opacity-0 group-hover:opacity-100')}
              icon={<Edit />}
              onClick={() => startEditing?.(comment.id!, comment.raw ?? text)}
            />
          </Tooltip>
        )}
      </Component>
    </>
  )
}

function useMyMessageStatusProps(
  isMyMessage: boolean,
  sendStatus: 'sending' | 'failed' | undefined,
  readReceipts: undefined | { [receiver: string]: ReadReceipt },
  itemFollowers?: List<Map<keyof Follower, string>>
): ChatMyMessageProps | null {
  const me = useMe()
  const { t } = useTranslation()
  if (!isMyMessage) return null
  const readReceiptsList = readReceipts && Object.values(readReceipts).reverse()
  const seenByEveryone = itemFollowers?.every(
    follower => !follower || follower.get('id') === userHelper.getID(me) || !!readReceipts?.[follower.get('id')]
  )

  return {
    status: sendStatus ?? 'received',
    statusIcon: (
      <Tooltip
        relationship="label"
        content={
          sendStatus === 'sending' ? (
            t('item_chat.sending')
          ) : sendStatus === 'failed' ? (
            t('item_chat.failed')
          ) : !readReceiptsList || readReceiptsList.length === 0 ? (
            t('item_chat.not_seen_yet')
          ) : seenByEveryone ? (
            t('item_chat.seen_by_everyone')
          ) : (
            <>
              <div>{t('item_chat.seen_by')}:</div>
              {readReceiptsList.map(readReceipt => (
                <div key={readReceipt.receiverURI}>- {readReceipt.receiverFullname}</div>
              ))}
            </>
          )
        }
      >
        {sendStatus === 'sending' ? (
          <Circle16Regular />
        ) : sendStatus === 'failed' ? (
          <Warning16Filled />
        ) : seenByEveryone ? (
          <Eye16Filled />
        ) : (
          <CheckmarkCircle16Regular />
        )}
      </Tooltip>
    ),
  }
}
