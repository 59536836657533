import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { ItemDetailView } from '../ItemDetail/ItemDetail'
import { searchViewContainerGenerator } from '../../containers/searchViewContainer'
import { MainLayout } from '../../components/layout/MainLayout'
import * as queryParamsHelper from '../../helpers/queryParamsHelper'
import { getRelativeURLKeepingQuerySearch, isInApp } from '../../helpers/routeHelper'
import { graphStateHelper, itemHelper } from '../../common/src/helpers'
import { OfficeSplitView } from '../officeViews/officeSplit/OfficeSplitView'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { SearchTopBar } from './SearchTopBar'
import { FILTER_REDUCER_KEYS, ITEM_FILTER_SECTION_KEY } from '../../common/src/reducers/filtersKeys'
import { useParamsForItemsFilter } from '../../common/src/hooks/filtersHooks'
import { getResourcesByText } from '../../common/src/actions/graphAPI'
import { getSearchItem } from '../../common/src/actions/searchAPI'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { RIGHT_PANEL } from '../../actions/uiActions'
import {
  useExtendQueryParametersToCheckBannerInfo,
  useExtendQueryParametersWithSearchLoopback,
  useNeedToCheckSearchLookbackBanner,
} from '../../common/src/hooks/searchHooks'
import { SearchLookbackBannerForTable } from '../../components/table/SearchLookbackBannerForTable'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { useTranslation } from 'react-i18next'
import { useInitializeMsCollaborators, useRightPanel, useSelectedGraphItem } from '../../hooks/PMHooks'
import { BaseFlexRow } from '../../components/layout/FlexContainer'
import { useFrequentFilterData, frequentFilterButtonExtraStyles } from '../filters/ItemsFiltersView'
import { ResponsiveButton } from '../../components/buttons/ResponsiveButton'
import * as viewInitializationActions from '../../actions/viewInitializationActions'
import { STATE_FILTER, setItemsStateFilter } from '@/common/src/actions/filtersActions'

const DEFAULT_LIMIT = 50

const PRESETS = {
  FINISHED: 'finished',
}

const presetApplication = dispatch => ({
  [PRESETS.FINISHED]: () => {
    dispatch(setItemsStateFilter(STATE_FILTER.FINISHED, FILTER_REDUCER_KEYS.SEARCH))
  },
})

const useConsumeFilterPreset = ({ history }) => {
  const dispatch = useDispatch()
  const { href } = window.location
  useLayoutEffect(() => {
    try {
      const url = new URL(href)
      const preset = url.searchParams.get(queryParamsHelper.QUERY_PARAMS.FILTER_PRESET)
      const app = preset && presetApplication(dispatch)[preset]
      if (!app) return
      app()
      url.searchParams.delete(queryParamsHelper.QUERY_PARAMS.FILTER_PRESET)
      history.replace(url)
    } catch (e) {
      console.log(e)
    }
  }, [dispatch, href, history])
}

const SearchView_ = memo(({ searchedItems, searchedGraphItems, history, match, selectedItem }) => {
  const dispatch = useDispatch()

  useInitializeMsCollaborators()
  useEffect(() => {
    dispatch(viewInitializationActions.searchView())
  }, [dispatch])
  useConsumeFilterPreset({ history })

  const [loading, setLoading] = useState(false)
  const [loadingGraph, setLoadingGraph] = useState(false)
  const offsetRef = useRef(0)
  const narrowWidth = useNarrowWidth()
  const inApp = isInApp(match.path)
  const isEmbedded = queryParamsHelper.isEmbedded()
  const isEmbeddedOnTeams = queryParamsHelper.isEmbeddedOnTeams()
  const isEmbeddedOnOutlook = queryParamsHelper.isEmbeddedOnOutlook()
  const isGraphEnabled = useSelector(graphStateHelper.isGraphEnabled)
  const canBeEnabledOffice = !isEmbedded
  const isEnabledOffice = isGraphEnabled && canBeEnabledOffice
  const showDetailView = !isEmbedded || isEmbeddedOnTeams || isEmbeddedOnOutlook
  const [bannerCount, setBannerCount] = useState(0)

  const selectedGraphItem = useSelectedGraphItem()
  const rightPanel = useRightPanel()
  const showingItem = rightPanel === RIGHT_PANEL.ITEM
  const showingGraphItem = rightPanel === RIGHT_PANEL.GRAPH_RESOURCE

  const needToCheckLookback = useNeedToCheckSearchLookbackBanner()

  const { t } = useTranslation()

  const updateOffsetRef = useCallback(hasNext => {
    if (hasNext) {
      offsetRef.current = offsetRef.current + DEFAULT_LIMIT
    } else {
      offsetRef.current = -1
    }
  }, [])

  const search = useCallback(
    async (params, itemsFound = 0) => {
      const finalParams = {
        ...params,
        offset: offsetRef.current < 0 ? 0 : offsetRef.current,
        limit: DEFAULT_LIMIT,
      }
      setLoading(true)
      try {
        const result = await dispatch(getSearchItem(finalParams))
        const hasNext = !!_.get(result, 'payload.meta.next')
        updateOffsetRef(hasNext)
        itemsFound += result?.payload?.objects?.length
        // If there is a next page and we didn't fill the limit yet, we need to download the next page
        if (hasNext && itemsFound < DEFAULT_LIMIT) {
          await search(params, itemsFound)
        }
      } finally {
        setLoading(false)
      }
    },
    [dispatch, updateOffsetRef]
  )
  const debouncedSearch = useMemo(() => _.debounce(search, 300, { trailing: true }), [search])

  const searchGraph = useCallback(
    async params => {
      const text = params.q
      if (_.isEmpty(text)) {
        return
      }
      setLoadingGraph(true)
      try {
        await dispatch(getResourcesByText(text))
      } finally {
        setLoadingGraph(false)
      }
    },
    [dispatch]
  )
  const debouncedSearchGraph = useMemo(() => _.debounce(searchGraph, 300, { trailing: true }), [searchGraph])

  const baseParams = useParamsForItemsFilter(FILTER_REDUCER_KEYS.SEARCH)

  const params = useExtendQueryParametersWithSearchLoopback(baseParams)
  const lookbackParams = useExtendQueryParametersToCheckBannerInfo(baseParams)

  const checkLookbackItems = useCallback(async () => {
    try {
      const result = await dispatch(getSearchItem(lookbackParams, `lookback`))
      const totalCount = result?.payload?.meta?.total_count ?? 0
      setBannerCount(totalCount)
    } catch {}
  }, [dispatch, lookbackParams])

  useEffect(() => {
    offsetRef.current = 0
    debouncedSearch(params)
  }, [params, debouncedSearch])

  useEffect(() => {
    if (isGraphEnabled) {
      debouncedSearchGraph(params)
    }
  }, [isGraphEnabled, debouncedSearchGraph, params])

  const onSelectItem = useCallback(
    item => {
      const itemID = itemHelper.getId(item)
      if (narrowWidth) {
        const path = getRelativeURLKeepingQuerySearch.itemViewInAppForID(itemID)
        return history.push(path)
      }
      const getPath = inApp ? getRelativeURLKeepingQuerySearch.searchApp : getRelativeURLKeepingQuerySearch.search
      history.push(getPath(itemID))
    },
    [history, inApp, narrowWidth]
  )

  const onSelectGraphItem = useCallback(
    graphItem => {
      if (narrowWidth) {
        const link = getRelativeURLKeepingQuerySearch.graphResourceViewInAppForID(graphItem.id)
        return history.push(link)
      }
      const getPath = inApp ? getRelativeURLKeepingQuerySearch.searchApp : getRelativeURLKeepingQuerySearch.search
      history.push(getPath(`graph-${graphItem.id}`))
    },
    [narrowWidth, inApp, history]
  )

  const onRenderLastItem = useCallback(() => {
    if (loading) {
      return
    }
    if (offsetRef.current < 0) {
      if (needToCheckLookback) {
        checkLookbackItems()
      }
      return
    }
    search(params)
  }, [loading, search, params, needToCheckLookback, checkLookbackItems])

  const items = useMemo(() => {
    const array = searchedItems.toArray()
    if (bannerCount) {
      array.push(SearchLookbackBannerForTable(bannerCount))
    }
    return array
  }, [searchedItems, bannerCount])

  const graphItems = useMemo(() => {
    return isEnabledOffice ? searchedGraphItems.toArray() : []
  }, [isEnabledOffice, searchedGraphItems])

  const placeholders = useMemo(() => {
    return [
      {
        title: t('search.placeholder_0.title'),
        message: t('search.placeholder_0.message'),
        type: 1,
      },
    ]
  }, [t])

  const officePlaceholders = useMemo(() => {
    return [
      {
        title: t('search.office_placeholder_0.title'),
        message: t('search.office_placeholder_0.message'),
        type: 1,
      },
    ]
  }, [t])

  const placeholder = useRandomValueFromArray(placeholders)
  const officePlaceholder = useRandomValueFromArray(officePlaceholders)

  const components = {
    topBar: <SearchTopBar inApp={inApp} items={items} />,
  }

  const officeView = (
    <OfficeSplitView
      items={items}
      selectedItem={showingItem ? selectedItem : null}
      graphItems={graphItems}
      selectedGraphItem={showingGraphItem ? selectedGraphItem : null}
      onSelectItem={onSelectItem}
      onSelectGraphItem={onSelectGraphItem}
      onRenderLastItem={onRenderLastItem}
      canBeEnabledOffice={canBeEnabledOffice}
      loading={loading}
      loadingGraph={loadingGraph}
      placeholder={placeholder}
      officePlaceholder={officePlaceholder}
      filterMode={ITEM_FILTER_SECTION_KEY.SEARCH}
    />
  )
  const frequentFiltersRow = <FrequentFiltersRow />

  components.main = <MainLayout main={officeView} topBar={frequentFiltersRow} />

  if (showDetailView) {
    components.rightSide = isEnabledOffice ? <RightDetailView /> : <ItemDetailView />
  }

  return <MainLayout {...components} />
})

const FrequentRow = styled(BaseFlexRow)`
  margin: 12px;
  & > *:not(:first-child) {
    padding-left: 3px;
    margin-top: ${p => (p.narrowWidth ? 0 : -6)}px;
  }
`

const FrequentFiltersRow = React.memo(() => {
  const { t } = useTranslation()
  const { data: frequentFilterData, sortedKeys: allFilterKeys } = useFrequentFilterData(FILTER_REDUCER_KEYS.SEARCH)
  const N_BUTTONS = 3
  const narrowWidth = useNarrowWidth()
  return (
    <FrequentRow narrowWidth={narrowWidth}>
      {!narrowWidth && <span>{t('search.frequent_filters')}</span>}
      {_.map(_.take(allFilterKeys, N_BUTTONS), k => {
        const d = frequentFilterData[k]
        return (
          <ResponsiveButton alwaysWide key={k} onClick={d.onClick} styles={frequentFilterButtonExtraStyles}>
            {t(d.nameKey)}
          </ResponsiveButton>
        )
      })}
    </FrequentRow>
  )
})

export const SearchView = withRouter(searchViewContainerGenerator(SearchView_))
