import { useMemo } from 'react'
import _ from 'lodash'
import { getRandomValueFromArray } from '../utils/arrayUtils'

export const useRandomValueFromArray = (array, seconds = 60) => {
  seconds = seconds > 0 ? seconds : 60
  const currentSecond = _.toInteger(new Date().getTime() / 1000)
  const flag = _.toInteger(currentSecond / seconds)
  return useMemo(() => {
    return getRandomValueFromArray(array)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, array])
}
