import { type Editor } from '@tiptap/react'
import { useTranslation } from 'react-i18next'
import { ShortcutTooltipHost } from '../../../tooltip/ShortcutTooltipHost'
import { META_KEY } from '../../shortcutHelp/ShortcutHelp'
import { IconButton } from '@fluentui/react'
import { cn } from '@appfluence/classnames'
import type { ReactNode } from 'react'

export type TipTapToolbarProps = {
  editor: Editor | null
  className?: string
  additionalButtons?: ReactNode
}
export const TipTapToolbar = ({ editor, className, additionalButtons }: TipTapToolbarProps) => {
  const { t } = useTranslation()
  return (
    <div className={cn('flex gap-1', className)}>
      <ShortcutTooltipHost tooltipKeys={[META_KEY, '⇧', '8']} content={t('item_detail.notes.toggle_bullet_list')}>
        <IconButton
          iconProps={{ iconName: 'BulletedList' }}
          toggle
          checked={editor?.isActive('bulletList')}
          onClick={() => editor?.chain().focus().toggleBulletList().run()}
        />
      </ShortcutTooltipHost>
      <ShortcutTooltipHost tooltipKeys={[META_KEY, '⇧', '9']} content={t('item_detail.notes.toggle_task_list')}>
        <IconButton
          iconProps={{ iconName: 'CheckList' }}
          toggle
          checked={editor?.isActive('taskList')}
          onClick={() => editor?.chain().focus().toggleTaskList().run()}
        />
      </ShortcutTooltipHost>
      {additionalButtons}
    </div>
  )
}
