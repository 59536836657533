import { useMutation, useQuery } from '@tanstack/react-query'
import { useDispatch, useStore } from 'react-redux'
import { getAttentionNeeded } from '@/common/src/actions/itemsAPI'
import { addMonths } from 'date-fns'
import { sendItem } from '@/common/src/actions/combinedAPI'
import { useEffect, useState } from 'react'

export const useAttentionNeededQuery = () => {
  const store = useStore()
  return useQuery({
    queryKey: ['attentionNeeded'],
    queryFn: () => {
      return store.dispatch(
        getAttentionNeeded({
          timestamp__gte: addMonths(new Date(), -2).toISOString(),
          add_mentions: true,
        })
      )
    },
    staleTime: 1000 * 60 * 5,
  })
}

export const useMutateItem = (itemId: number) => {
  const dispatch = useDispatch()
  const [internalItemId, setInternalItemId] = useState(itemId)

  // In ItemDetail we have a useEffect with cleanup code to send the last changes to an item
  // when we change to another item.
  // If we just use itemId as the scope for the mutation, the cleanup code will be called with the new itemId as scope,
  // resulting in that mutation not being enqueued for the previous item.
  // Adding the following useEffect will make sure that the scope is correct when changing items.
  useEffect(() => {
    if (internalItemId !== itemId) {
      setInternalItemId(itemId)
    }
  }, [internalItemId, itemId])

  return useMutation({
    mutationFn: item => {
      return dispatch(sendItem(item))
    },
    scope: {
      id: `item-${internalItemId}`,
    },
  })
}
