import React from 'react'
import { ItemsFiltersView } from './ItemsFiltersView'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { ITEMS_SORT_TYPE } from '../../common/src/actions/filtersActions'
import { Sections } from './ItemFiltersView.Common'

const sortTypeBlacklist = [ITEMS_SORT_TYPE.INDEX, ITEMS_SORT_TYPE.PROJECT]

export const InboxFiltersView = ({ ...rest }) => {
  return (
    <ItemsFiltersView
      filtersType={FILTER_REDUCER_KEYS.INBOX}
      showSelectColumnsButton
      sortTypeBlacklist={sortTypeBlacklist}
      hideSections={[Sections.Sort]}
      {...rest}
    />
  )
}
