import { useContext, useState } from 'react'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { safeCallFn } from '../../common/src/helpers/functionalUtils'
import { bestTextColorForBackgroundColor, quadrantColorStringFromObject } from '../../common/src/helpers/colorsHelpers'
import { ViewSizeContext } from '../../contexts'
import { PlusButton } from '../buttons/PlusButton'
import { useTranslation } from 'react-i18next'
import { Button, Input } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { projectHelper } from '../../common/src/helpers'
import { useDispatch } from 'react-redux'
import { markProjectAsModifiedInModel, sendProject } from '../../common/src/actions/combinedAPI'
import { ColorsPanel } from '../panels/colors/ColorsPanel'
import { quadrantColorObjects } from '../../templates/quadrantColorObjects'
import { ColorRegular } from '@fluentui/react-icons'

const Container = styled.div`
  background: ${p => p.background};
  text-align: left;
  font-weight: bolder;
  margin: 0;
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 24px;
  &.narrow {
    height: 16px;
  }
`

const StyledPlusButton = styled(PlusButton).attrs({
  size: 14,
})`
  margin-right: 4px;
`

// TODO: this should be more dynamic
const QuadrantName = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${p => p.textColor};

  margin-left: 0;
  margin-right: 4px;
`

const AddButtonContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${p => p.theme.palette.themePrimary};
  white-space: nowrap;
  border-radius: 2px;
  padding: 2px 6px;
  cursor: pointer;
`

const AddButtonText = styled.span`
  color: ${p => p.theme.palette.white};
`

export const AddButton = ({ ...rest }) => {
  const { t } = useTranslation()
  return (
    <AddButtonContainer {...rest}>
      <StyledPlusButton />
      <AddButtonText>{t('item.create_item')}</AddButtonText>
    </AddButtonContainer>
  )
}

export const StyledAddButton = styled(AddButton)`
  margin-right: 12px;
  opacity: 0;
`

const useChangeProperty = project => {
  const dispatch = useDispatch()
  return (property, newValue = '') => {
    const newProject = project.set(property, newValue)
    dispatch(markProjectAsModifiedInModel(newProject))
    dispatch(sendProject(newProject))
  }
}

const useSaveQuadrantName = (project, quadrant) => {
  const changeProperty = useChangeProperty(project)
  return (newName = '') => {
    changeProperty(projectHelper.QUADRANT_NAMES[quadrant], newName)
  }
}

const useChangeQuadrantColor = (project, quadrant) => {
  const changeProperty = useChangeProperty(project)
  return newColor => {
    changeProperty(projectHelper.QUADRANT_COLORS[quadrant], newColor)
  }
}

export const QuadrantHeader = ({
  q = 0,
  name = '',
  background = 'white',
  textColor = 'black',
  onClickAdd,
  readOnly = false,
  className,
  itemCount,
  project,
  ...rest
}) => {
  const saveName = useSaveQuadrantName(project, q)
  const changeColor = useChangeQuadrantColor(project, q)
  const [editMode, setEditMode] = useState(false)
  const [colorsPanelOpen, setColorsPanelOpen] = useState(false)
  const { t } = useTranslation()
  const { narrowWidth } = useContext(ViewSizeContext)
  const addButtonTextColor = bestTextColorForBackgroundColor(background)
  const onClick = () => safeCallFn(onClickAdd)
  const narrowClassname = cn({ narrow: narrowWidth })
  const containerClassname = cn(className, narrowClassname)
  const shouldDisplayAddButton = !readOnly && !narrowWidth
  const handleDoubleClick = () => {
    setEditMode(true)
  }
  const handleInputKeyDown = e => {
    if (e.key === 'Enter') {
      saveName(e.target.value)
      setEditMode(false)
    }
    if (e.key === 'Escape') {
      setEditMode(false)
    }
  }
  const handleColorChange = colorObject => {
    changeColor(quadrantColorStringFromObject(colorObject))
    setColorsPanelOpen(false)
    setEditMode(false)
  }
  const handleOpenColorPanel = () => {
    setColorsPanelOpen(true)
  }
  const handleDismissColorPanel = () => {
    setColorsPanelOpen(false)
  }

  return (
    <Container background={background} className={containerClassname} onDoubleClick={handleDoubleClick} {...rest}>
      {!editMode && (
        <>
          <div
            className="ml-2 mr-1 flex w-[28px] justify-center font-normal opacity-50"
            title={t('quadrant.count_title')}
            style={{ color: textColor }}
          >
            {itemCount || ''}
          </div>
          <QuadrantName id={`quadrantHeader_quadrantName_${q}`} textColor={textColor}>
            {name}
          </QuadrantName>
          {shouldDisplayAddButton && (
            <StyledAddButton id={'quadrantHeader_addButton'} textColor={addButtonTextColor} onClick={onClick} />
          )}
        </>
      )}
      {editMode && (
        <>
          <Tooltip relationship="label" content={t('quadrant.change_color_tooltip')}>
            <Button
              icon={<ColorRegular />}
              size="small"
              onClick={handleOpenColorPanel}
              style={{ background }}
              className="!ml-2 !mr-1"
            />
          </Tooltip>
          <Input
            autoFocus
            defaultValue={name}
            appearance="underline"
            onKeyDown={handleInputKeyDown}
            className="flex-1 !pl-1"
          />
          <ColorsPanel
            isOpen={colorsPanelOpen}
            colorObjects={quadrantColorObjects}
            onChangeColor={handleColorChange}
            onDismiss={handleDismissColorPanel}
          />
        </>
      )}
    </Container>
  )
}
