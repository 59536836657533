import styled from 'styled-components'
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState, useMemo, memo, isValidElement } from 'react'
import { List } from 'immutable'
import { Draggable, Droppable } from '@hello-pangea/dnd'
import { BasicItemList } from '../item/basicList/BasicItemList'
import { QuadrantHeader, StyledAddButton } from './QuadrantHeader'
import { QuadrantPlaceholder } from './QuadrantPlaceholder'
import { itemHelper } from '../../common/src/helpers'
import { mapValuesToJsArray } from '../../common/src/helpers/immutableHelpers'
import { scrollToItem } from '../../reactions'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useSingleAndDoubleClick } from '../../common/src/hooks/useSingleAndDoubleClick'
import { useCellStyles } from '../itemCell/ItemCellStyles'
import { GhostItemsList } from '../list/GhostItemsList'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu'
import { useTranslation } from 'react-i18next'
import { Column } from '../layout/FlexContainer'
import { ItemCell } from '@/components/itemCell/ItemCell.js'
import { GhostItemCell } from '@/components/itemCell/GhostItemCell'
import { hasTouchScreen } from '@/helpers/deviceDetectionHelper'
import { Portal } from '@fluentui/react-components'

const contextualMenuKeys = {
  new: 'new',
}

const StyledQuadrantHeader = styled(QuadrantHeader)``

const QuadrantContainer = styled(Column)`
  user-select: none;

  ${StyledAddButton} {
    display: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover ${StyledAddButton} {
      display: inherit;
      opacity: 1;
    }
  }
`

const ListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${p => p.background};
  position: relative;

  &.resizing {
    overflow: hidden;
  }
`

const useItemListStyles = (background, text) => {
  const defStyles = useCellStyles()
  return useMemo(() => {
    return {
      ...defStyles,
      text,
      background,
    }
  }, [background, defStyles, text])
}

const Quadrant_ = ({
  items = List(),
  isFiltering = false,
  onClick,
  headerBackgroundColor,
  headerTextColor,
  bodyBackgroundColor,
  cellTextColor,
  name,
  selectedItemId,
  listClassName = '',
  idx,
  narrow = false,
  readOnly = false,
  loading = false,
  project,
  showOwner = true,
}) => {
  const virtualize = !hasTouchScreen()
  const listRef = useRef(null)
  const itemsRef = useRef(null)
  const createItemModal = useCreateItemModal()
  const listStyles = useItemListStyles(bodyBackgroundColor, cellTextColor)
  useEffect(() => {
    const subscription = scrollToItem.subscribe(item => {
      const id = itemHelper.getId(item)
      const quadrant = itemHelper.getQuadrant(item)
      if (quadrant !== idx) {
        return
      }

      const itemList = itemsRef.current
      if (_.isNumber(id) && itemList) {
        const itemIdx = _.findIndex(itemList, i => itemHelper.getId(i) === id)
        if (itemIdx >= 0) {
          const element = listRef.current
          if (element && element.scrollToIndex) {
            listRef.current.scrollToIndex({ index: itemIdx, align: 'center' })
          }
        }
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [idx])
  const l = mapValuesToJsArray(items, _.identity)
  itemsRef.current = l

  const { t } = useTranslation()
  const [showContextualMenu, setShowContextualMenu] = useState(false)
  const [contextualMenuTarget, setContextualMenuTarget] = useState(undefined)

  const handleShowContextualMenu = event => {
    event.preventDefault()
    event.stopPropagation()
    setContextualMenuTarget(event)
    setShowContextualMenu(true)
  }

  const handleHideContextualMenu = () => {
    setShowContextualMenu(false)
  }

  const openNewItemModal = useCallback(
    mode => {
      createItemModal({
        quadrant: idx,
        initialProject: project,
        mode,
      })
    },
    [createItemModal, idx, project]
  )

  const handleAddItem = () => {
    openNewItemModal(EVENT_EXTRA.CREATE_ITEM.MODE.CONTEXTUAL_MENU)
  }

  const contextualMenuOptions = [
    {
      id: 'itemList_new',
      key: contextualMenuKeys.new,
      text: t('item.create_item'),
      iconProps: { iconName: 'Add' },
      onClick: handleAddItem,
      hidden: readOnly,
    },
  ].filter(o => !o.hidden)

  const handleDoubleClick = useCallback(() => {
    openNewItemModal(EVENT_EXTRA.CREATE_ITEM.MODE.DOUBLE_CLICK)
  }, [openNewItemModal])
  const handleClick = useSingleAndDoubleClick(undefined, handleDoubleClick)

  const onClickAddHeader = () => {
    openNewItemModal(EVENT_EXTRA.CREATE_ITEM.MODE.MATRIX_TOP_BAR)
  }

  const renderDroppable = (provided, snapshot) => {
    const { isDraggingOver } = snapshot
    const list = isDraggingOver ? [...l, provided.placeholder] : l
    return (
      <ListContainer
        ref={provided.innerRef}
        background={bodyBackgroundColor}
        className={listClassName}
        onClick={readOnly ? undefined : handleClick}
      >
        {l.length === 0 && <QuadrantPlaceholder count={l.length} isFiltering={isFiltering} readOnly={readOnly} />}
        {list.map((item, idx) => {
          if (isValidElement(item)) return item
          if (!item || !item.get) {
            return <GhostItemCell key={idx} />
          }
          const id = item.get('id')
          return (
            <Draggable draggableId={id?.toString()} index={idx} key={id?.toString() ?? idx}>
              {(provided, snapshot) => {
                return (
                  <ItemCell
                    allItemsInList={items}
                    item={item}
                    idx={idx}
                    onClick={onClick}
                    styles={listStyles}
                    selected={id === selectedItemId}
                    narrow={narrow}
                    readOnly={readOnly}
                    innerRef={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    showOwner={showOwner}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                  />
                )
              }}
            </Draggable>
          )
        })}
      </ListContainer>
    )
  }

  const renderVirtualizedDroppable = (provided, snapshot) => {
    const { isDraggingOver } = snapshot
    return (
      <ListContainer
        background={bodyBackgroundColor}
        className={listClassName}
        onClick={readOnly ? undefined : handleClick}
      >
        {l.length === 0 && <QuadrantPlaceholder count={l.length} isFiltering={isFiltering} readOnly={readOnly} />}
        <BasicItemList
          items={l}
          onClickItem={onClick}
          selectedItemId={selectedItemId}
          itemCellStyles={listStyles}
          readOnly={readOnly}
          draggable
          placeholder={isDraggingOver && provided.placeholder}
          narrow={narrow}
          ref={listRef}
          showOwner={showOwner}
          scrollerRef={provided.innerRef}
          {...provided.droppableProps}
        />
      </ListContainer>
    )
  }
  return (
    <>
      <QuadrantContainer onContextMenu={handleShowContextualMenu}>
        <StyledQuadrantHeader
          itemCount={l.length}
          q={idx}
          name={name}
          background={headerBackgroundColor}
          textColor={headerTextColor}
          onClickAdd={onClickAddHeader}
          readOnly={readOnly}
          project={project}
        />
        {loading ? (
          <ListContainer background={bodyBackgroundColor}>
            <GhostItemsList shimmerColors={{ background: bodyBackgroundColor }} />
          </ListContainer>
        ) : virtualize ? (
          <Droppable
            mode="virtual"
            droppableId={`q${idx}`}
            renderClone={(provided, snapshot, rubric) => (
              <Portal>
                <ItemCell
                  item={l[rubric.source.index]}
                  selected={l[rubric.source.index].get('id') === selectedItemId}
                  narrow={narrow}
                  readOnly={readOnly}
                  innerRef={provided.innerRef}
                  isDragging={snapshot.isDragging}
                  showOwner={showOwner}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                />
              </Portal>
            )}
          >
            {renderVirtualizedDroppable}
          </Droppable>
        ) : (
          <Droppable droppableId={`q${idx}`}>{renderDroppable}</Droppable>
        )}
      </QuadrantContainer>
      <ContextualMenu
        items={contextualMenuOptions}
        hidden={!showContextualMenu}
        target={contextualMenuTarget}
        onDismiss={handleHideContextualMenu}
      />
    </>
  )
}

export const Quadrant = memo(Quadrant_)
