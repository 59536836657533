import styled from 'styled-components'
import { forwardRef, memo, startTransition, Suspense, useCallback, useMemo, useState } from 'react'
import { useStore, useSelector, useDispatch } from 'react-redux'
import { itemHelper, projectHelper, stateHelper } from '../../common/src/helpers'
import { IconCell } from '../icon/IconCell'
import { UserCollectionCell } from '../users/usersCollection/UserCollectionCell.js'
import { GROUP_BY, setItemsSortAscending, setItemsSortType } from '../../common/src/actions/filtersActions'
import { API_ACCEPTED_SORT_KEYS, getGenericSortPredicateForItems } from '../../common/src/helpers/itemsFiltersHelper'
import { Loading } from '../basic/Loading'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { OfficeLikeQuadrantSelector as QuadrantSelector } from '../input/quadrant/OfficeLikeQuadrantSelector'
import { useTranslation } from 'react-i18next'
import { ItemContextualMenu } from '../item/ItemContextualMenu'
import { LazyIViewPlaceholder } from '../placeholder/LazyIViewPlaceholder'
import { useGetItemWhenOpenContextualMenu } from '../../hooks/itemContextualMenu'
import { useMobile } from '../../helpers/responsiveHelpers'
import { OwnerPanel } from '../item/OwnerPanel'
import { markItemAsModified, sendItem } from '../../common/src/actions/combinedAPI'
import { useUsersMenuProps } from '../../hooks/usersMenuPropsHooks'
import { useUserForEmail } from '../../common/src/hooks/usersHooks'
import { FlexColumn } from '../layout/FlexContainer'
import {
  getItemsShowAvatars,
  getMultipleSelectedItems,
  getPreferredMatrixViewMode,
  isItemMultipleSelectionOn,
} from '../../selectors/uiSelectors'
import {
  clearItemMultipleSelection,
  setItemSelectedInMultipleSelection,
  setItemsSelectedInMultiSelection,
} from '../../actions/uiActions'
import {
  getItemsGroupByFilter,
  getItemsSortAscending,
  getItemsSortType,
  getItemsStarredFirst,
} from '../../common/src/selectors/filtersSelectors'
import { ITEM_FILTER_SECTION_KEY } from '../../common/src/reducers/filtersKeys'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { Map } from 'immutable'
import {
  Button,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Skeleton,
  SkeletonItem,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSelectionCell,
} from '@fluentui/react-components'
import { usePositioningMouseTarget } from '@fluentui/react-positioning'
import { formatDate } from '../../utils/datefns'
import { useChangeIcon } from '../../typedContexts'
import { cn } from '@/modules/classnames'
import {
  CheckmarkFilled,
  FlagRegular,
  GridRegular,
  PersonEditRegular,
  PersonRegular,
  StarFilled,
} from '@fluentui/react-icons'
import { Info } from '@/components/BundledIcons'
import { Link } from 'react-router-dom'
import { getRelativePathToMatrixBasedOnMode } from '@/helpers/routeHelper'
import { useMutateItem } from '@/queries/items'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { TableVirtuoso } from 'react-virtuoso'
import { useItemClick } from '@/hooks/PMHooks.js'
import { ITEM_COLUMN_KEYS } from '@/components/table/itemColumnKeys'
import { useAtom } from 'jotai'
import { itemTableColumnsAtoms } from '@/atoms/itemTableColumnsAtoms'
import {
  customEffortFormatter,
  EFFORT_REVERSE_MAP,
  EFFORT_SHORT_TRANSLATION_KEYS,
} from '@/components/effort/commonEffort'

const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
}

const IMAGE_SIZE = 24

const IconContainer = styled.div`
  position: relative;
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
`

const SIcon = styled(IconCell).attrs({
  side: IMAGE_SIZE,
})`
  margin: auto;
`

const Star = () => <StarFilled className="absolute -left-0.5 -top-0.5 text-sm text-yellow-400" />

const Owner = ({ email, onClick }) => {
  const user = useUserForEmail(email)
  return (
    <UserCollectionCell
      className="mx-auto"
      onClick={onClick}
      personaProps={{ avatar: { size: IMAGE_SIZE } }}
      user={user}
      hidePersonaDetails
    />
  )
}

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: ${p => p.theme.palette.black};
`

const TextContainer = styled(FlexColumn)`
  align-items: flex-start;
`

const Percentage = styled.div`
  color: ${p => p.theme.palette.black};
`

const addStyleForCompletedItem = (item, base = {}) => {
  const c = itemHelper.getCompletionPercentage(item)
  if (c >= 100) {
    base.textDecoration = 'line-through'
  }
  return base
}

const sortItems = (array, sortBy, sortDirection, starredFirst) => {
  const isAscending = sortDirection === SortDirection.ASC
  const predicate = getGenericSortPredicateForItems(sortBy, isAscending, starredFirst)
  return [...array].sort(predicate)
}

const OwnerPanelComponent = ({ item, isOpenOwnerView, hideOwnerView }) => {
  const username = itemHelper.getOwnerUsername(item)
  const owner = useUserForEmail(username)

  const projectID = itemHelper.getProjectIdd(item)
  const project = useSelector(state => stateHelper.getProject(state, projectID))
  const menuProps = useUsersMenuProps({ users: owner ? [owner] : null, object: item })
  const dispatch = useDispatch()

  const { mutate: mutateItem } = useMutateItem(itemHelper.getId(item))

  const changeOwnerProperty = useCallback(
    username => {
      const finalItem = item.set(itemHelper.KEYS.OWNER_USERNAME, username)
      startTransition(() => void dispatch(markItemAsModified(finalItem)))
      mutateItem(finalItem)
    },
    [item, mutateItem, dispatch]
  )

  return (
    <OwnerPanel
      user={owner}
      project={project}
      onChange={changeOwnerProperty}
      isOpen={isOpenOwnerView}
      onDismiss={hideOwnerView}
      menuProps={menuProps}
    />
  )
}

const StarredHeader =
  content =>
  ({ row, ...rowProps }) => {
    return (
      <TableRow {...rowProps} className={cn('w-full cursor-auto pl-5 pr-2 hover:bg-transparent')}>
        <TableCell colSpan={row.getVisibleCells().length}>
          <div className="flex">{content}</div>
        </TableCell>
      </TableRow>
    )
  }

const columnHelper = createColumnHelper()

const QuadrantSelectorItem = ({ item }) => {
  const pid = itemHelper.getProjectIdd(item)
  const project = useSelector(state => stateHelper.getProject(state, pid))
  return project ? (
    <QuadrantSelector className="ml-0.5" size={IMAGE_SIZE} selected={itemHelper.getQuadrant(item)} project={project} />
  ) : null
}

const ProjectName = ({ item }) => {
  const humanizedProjectName = useSelector(state => stateHelper.getHumanizedProjectNameForItem(state, item))
  return <span className="text-xs text-pm-neutral-primary-alt">{humanizedProjectName}</span>
}

export const ItemVirtualizedTable = memo(
  ({
    items,
    selectedItem,
    isSortEnabled = false,
    loading = false,
    placeholder,
    displayProject = false,
    showQuadrant = false,
    filterMode = ITEM_FILTER_SECTION_KEY.PROJECT_LIST,
    queryingSearchAPI = false,
    showOwner: _showOwner = true,
    endReached,
    onRowClick,
  }) => {
    const showOwner = useSelector(getItemsShowAvatars) && _showOwner
    const { t } = useTranslation()
    const sortBy = useSelector(state => getItemsSortType(state, filterMode))
    const sortDirection = useSelector(state => getItemsSortAscending(state, filterMode))
      ? SortDirection.ASC
      : SortDirection.DESC
    const starredFirst = useSelector(state => getItemsStarredFirst(state, filterMode))
    const groupBy = useSelector(state => getItemsGroupByFilter(state, filterMode))

    const [showOwnerColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.OWNER])
    const [showQuadrantColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.QUADRANT])
    const [showProgressColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.PROGRESS])
    const [showDueDateColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.DUE_DATE])
    const [showStartDateColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.START_DATE])
    const [showLastModifiedColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.LAST_MODIFIED_TIMESTAMP])
    const [showIconColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.ICON])
    const [showEffortColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.EFFORT])
    const [showCompletionDateColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.COMPLETION_DATE])
    const [showCreatorColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.CREATOR])
    const [showCreationDateColumn] = useAtom(itemTableColumnsAtoms[ITEM_COLUMN_KEYS.CREATION_DATE])

    const store = useStore()
    const _rows = useMemo(() => {
      // If searching through API and sortBy is accepted by API, then items are already sorted
      if (queryingSearchAPI && API_ACCEPTED_SORT_KEYS.has(sortBy)) {
        return items
      }
      if (isSortEnabled && sortBy) {
        return sortItems(items, sortBy, sortDirection, starredFirst)
      }
      return items
    }, [queryingSearchAPI, sortBy, isSortEnabled, items, sortDirection, starredFirst])

    const projectViewMode = useSelector(getPreferredMatrixViewMode)

    const rowsWithoutLoading = useMemo(() => {
      if (_rows.length === 0 || (!starredFirst && groupBy === GROUP_BY.NONE)) return _rows
      if (groupBy !== GROUP_BY.NONE) {
        // Add a header before each item that changes project
        return _rows.reduce((acc, item, index) => {
          const pid = itemHelper.getProjectIdd(item)
          const project = stateHelper.getProject(store.getState(), pid)
          const prevItem = index > 0 ? _rows[index - 1] : null
          const prevPid = prevItem ? itemHelper.getProjectIdd(prevItem) : null
          if (prevItem && prevPid === pid) {
            return [...acc, item]
          }
          return [
            ...acc,
            {
              id: `project-header-${projectHelper.getIdd(project)}`,
              render: ({ row, ...rowProps }) => (
                <TableRow {...rowProps}>
                  <TableCell colSpan={row.getVisibleCells().length}>
                    <Link
                      to={getRelativePathToMatrixBasedOnMode(projectViewMode)(projectHelper.getIdd(project))}
                      {...rowProps}
                      className={cn(
                        rowProps.className,
                        'box-border flex h-full w-full items-center px-2 font-semibold text-black no-underline dark:text-white'
                      )}
                    >
                      {projectHelper.getName(project)}
                    </Link>
                  </TableCell>
                </TableRow>
              ),
            },
            item,
          ]
        }, [])
      }
      const starredItems = _rows.filter(itemHelper.isStarred)
      const notStarredItems = _rows.filter(item => !itemHelper.isStarred(item))
      const showStarredHeaders = starredItems.length > 0 && notStarredItems.length > 0
      if (!showStarredHeaders) return _rows
      return [
        ...[
          {
            id: 'starred-header',
            render: StarredHeader(
              <>
                <StarFilled className="text-base text-yellow-400" />
                <span className="ml-2 font-bold">{t('item.starred_items')}</span>
                <Popover>
                  <PopoverTrigger disableButtonEnhancement>
                    <Button className="!ml-1" icon={<Info className="text-base" />} appearance="subtle" size="small" />
                  </PopoverTrigger>
                  <PopoverSurface>{t('item.starred_items_info')}</PopoverSurface>
                </Popover>
              </>
            ),
          },
        ],
        ...starredItems,
        ...[
          {
            id: 'rest-header',
            render: StarredHeader(<span className="font-bold">{t('item.rest_items')}</span>),
          },
        ],
        ...notStarredItems,
      ]
    }, [_rows, groupBy, projectViewMode, starredFirst, store, t])
    const rows = useMemo(
      () =>
        loading
          ? [...rowsWithoutLoading, ...Array.from(Array(15).keys()).map(n => ({ id: `skeleton-${n}` }))]
          : rowsWithoutLoading,
      [loading, rowsWithoutLoading]
    )
    const narrow = useNarrowWidth()
    const [showContextualMenu, setShowContextualMenu] = useState(false)
    const [contextualMenuTarget, setContextualMenuTarget] = usePositioningMouseTarget()
    const [contextualMenuItem, setContextualMenuItem] = useState(undefined)
    const mobilePlatform = useMobile()
    const [isOpenOwnerView, setOpenOwnerView] = useState(false)
    const dispatch = useDispatch()
    const [actionItem, setActionItem] = useState(undefined)
    const multipleSelection = useSelector(isItemMultipleSelectionOn)
    const multipleSelectedItemIds = useSelector(getMultipleSelectedItems)

    const _handleRowClick = useCallback((event, item) => onRowClick({ event, rowData: item }), [onRowClick])
    const handleRowClick = useItemClick({ allItems: _rows, onClickItem: _handleRowClick })

    const handleSortChange = useCallback(
      ({ sortBy: newSortBy }) => {
        if (sortBy === newSortBy) {
          dispatch(setItemsSortAscending(sortDirection !== SortDirection.ASC, filterMode))
        } else {
          dispatch(setItemsSortType(newSortBy, filterMode))
        }
      },
      [dispatch, filterMode, sortBy, sortDirection]
    )

    useGetItemWhenOpenContextualMenu(contextualMenuItem, showContextualMenu)

    const showOwnerView = useCallback(
      (event, item) => {
        if (!mobilePlatform) {
          event.preventDefault()
          event.stopPropagation()
        }
        setActionItem(item)
        setOpenOwnerView(true)
      },
      [setOpenOwnerView, setActionItem, mobilePlatform]
    )

    const hideOwnerView = useCallback(() => {
      setOpenOwnerView(false)
    }, [setOpenOwnerView])

    const changeIconProperty = useCallback(
      (item, iconName) => {
        const finalItem = item.set(itemHelper.KEYS.ICON, iconName)
        startTransition(() => void dispatch(markItemAsModified(finalItem)))
        dispatch(sendItem(finalItem))
      },
      [dispatch]
    )

    const { openModal: openChangeIconModal } = useChangeIcon()

    const onClickIcon = useCallback(
      item => {
        const onChangeIcon = iconName => {
          changeIconProperty(item, iconName)
        }
        setActionItem(item)
        openChangeIconModal({ currentIcon: itemHelper.getIconNameSafelyWithoutExtension(item), onChangeIcon })
      },
      [changeIconProperty, openChangeIconModal]
    )

    const handleSelectAllItems = useCallback(() => {
      const ids = Map.isMap(items) ? items.keys() : items.map(item => itemHelper.getId(item))
      dispatch(setItemsSelectedInMultiSelection(ids))
    }, [dispatch, items])

    const handleShowContextualMenu = ({ event, rowData }) => {
      if (rowData === undefined) return
      event.preventDefault()
      setContextualMenuTarget(event)
      setContextualMenuItem(rowData)
      setShowContextualMenu(true)
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.ITEM_CONTEXTUAL_MENU_OPEN))
    }

    const columns = useMemo(
      () => [
        columnHelper.display({
          id: ITEM_COLUMN_KEYS.SELECT,
          header: ({ table }) => {
            const allChecked =
              (items.size ?? items.length) > 0 &&
              items.every(item => multipleSelectedItemIds.has(itemHelper.getId(item)))
            return (
              <TableSelectionCell
                checked={allChecked ? true : multipleSelectedItemIds.size > 0 ? 'mixed' : false}
                onChange={allChecked ? () => dispatch(clearItemMultipleSelection()) : handleSelectAllItems}
                checkboxIndicator={{ size: narrow ? 'large' : 'medium' }}
              />
            )
          },
          cell: ({ row }) => {
            const itemId = itemHelper.getId(row.original)
            const selected = multipleSelectedItemIds.has(itemId)
            return (
              <TableSelectionCell
                checkboxIndicator={{ size: narrow ? 'large' : 'medium' }}
                checked={selected}
                onClick={ev => {
                  ev.stopPropagation()
                }}
                onChange={ev => {
                  dispatch(setItemSelectedInMultipleSelection(itemId, ev.target.checked))
                }}
              />
            )
          },
          meta: { bypassDefaultCell: true },
        }),
        columnHelper.accessor(itemHelper.getQuadrant, {
          id: ITEM_COLUMN_KEYS.QUADRANT,
          header: () => (
            <div className="flex w-full items-center justify-center">
              <GridRegular className="text-xl" />
            </div>
          ),
          cell: props => <QuadrantSelectorItem item={props.row.original} />,
          meta: { size: 30 },
        }),
        columnHelper.accessor(itemHelper.getIconNameSafelyWithoutExtension, {
          id: ITEM_COLUMN_KEYS.ICON,
          header: () => (
            <div className="flex w-full items-center justify-center">
              <FlagRegular className="text-xl" />
            </div>
          ),
          cell: props => {
            const iconName = props.getValue()
            const isStarred = itemHelper.isStarred(props.row.original)

            return (
              <IconContainer>
                {iconName && <SIcon icon={iconName} onClick={() => onClickIcon(props.row.original)} />}
                {isStarred && <Star />}
              </IconContainer>
            )
          },
          meta: { size: 30 },
        }),
        columnHelper.accessor(itemHelper.getOwnerUsername, {
          id: ITEM_COLUMN_KEYS.OWNER,
          header: () => (
            <div className="flex w-full items-center justify-center">
              <PersonRegular className="text-xl" />
            </div>
          ),
          cell: props => <Owner email={props.getValue()} onClick={event => showOwnerView(event, props.row.original)} />,
          meta: { size: 40 },
        }),
        columnHelper.accessor(itemHelper.getCreatorUsername, {
          id: ITEM_COLUMN_KEYS.CREATOR,
          header: () => (
            <div className="flex w-full items-center justify-center">
              <PersonEditRegular className="text-xl" />
            </div>
          ),
          cell: props => {
            const creatorUsername = props.getValue()
            return <Owner email={creatorUsername} />
          },
          meta: { size: 40 },
        }),
        columnHelper.accessor(itemHelper.getName, {
          id: ITEM_COLUMN_KEYS.NAME,
          header: t('item.name'),
          cell: props => {
            const name = props.getValue()
            const style = addStyleForCompletedItem(props.row.original)
            return (
              <TextContainer>
                <Text style={style} title={name}>
                  {name}
                </Text>
                {displayProject && <ProjectName item={props.row.original} />}
              </TextContainer>
            )
          },
        }),
        columnHelper.accessor(itemHelper.getCompletionPercentage, {
          id: ITEM_COLUMN_KEYS.PROGRESS,
          header: t('item.percent'),
          cell: props => {
            const value = props.getValue()
            if (value >= 100) {
              return <CheckmarkFilled />
            } else if (value <= 0) {
              return false
            }
            return <Percentage>{value + '%'}</Percentage>
          },
          meta: { size: 40 },
        }),
        columnHelper.accessor(itemHelper.getEstimatedEffort, {
          id: ITEM_COLUMN_KEYS.EFFORT,
          header: t('item.effort.name'),
          cell: props => {
            const estimatedEffort = props.getValue()
            if (!estimatedEffort) {
              return false
            }
            let text = ''
            const effortKey = EFFORT_REVERSE_MAP[estimatedEffort]
            if (effortKey) {
              text = t(EFFORT_SHORT_TRANSLATION_KEYS[effortKey])
            } else {
              const { unit, value } = customEffortFormatter(estimatedEffort)
              text = t(`item.effort.short.custom_${unit}`, { count: value })
            }
            return <div className="w-full truncate text-pm-black">{text}</div>
          },
          meta: { size: 62 },
        }),
        columnHelper.accessor(itemHelper.getCreationDate, {
          id: ITEM_COLUMN_KEYS.CREATION_DATE,
          header: t('item.creation_date'),
          cell: props => {
            const value = props.getValue()
            if (!value) {
              return false
            }
            return <div className="w-full truncate text-pm-black">{formatDate('ShortenedAllDay')(value)}</div>
          },
          meta: { size: 90 },
        }),
        columnHelper.accessor(itemHelper.getStartDate, {
          id: ITEM_COLUMN_KEYS.START_DATE,
          header: t('item.start'),
          cell: props => {
            const value = props.getValue()
            if (!value) {
              return false
            }
            return <div className="w-full truncate text-pm-black">{formatDate('ShortenedAllDay')(value)}</div>
          },
          meta: { size: 90 },
        }),
        columnHelper.accessor(itemHelper.getDueDate, {
          id: ITEM_COLUMN_KEYS.DUE_DATE,
          header: t('item.due'),
          cell: props => {
            const value = props.getValue()
            if (!value) {
              return false
            }
            const isCompleted = itemHelper.getCompletionPercentage(props.row.original) >= 100
            const dueColor = itemHelper.getDueColor(
              props.row.original,
              'var(--color-affirmative)',
              'var(--color-negative)'
            )
            const style = dueColor ? { color: dueColor } : {}
            return (
              <div className={cn('w-full truncate text-pm-black', isCompleted && 'line-through')} style={style}>
                {formatDate('ShortenedAllDay')(value)}
              </div>
            )
          },
          meta: { size: 90 },
        }),
        columnHelper.accessor(itemHelper.getCompletionDate, {
          id: ITEM_COLUMN_KEYS.COMPLETION_DATE,
          header: t('item.completion_date'),
          cell: props => {
            const value = props.getValue()
            if (!value) {
              return false
            }
            return <div className="w-full truncate text-pm-black">{formatDate('ShortenedAllDay')(value)}</div>
          },
          meta: { size: 90 },
        }),
        columnHelper.accessor(itemHelper.getLastModifiedTimestampDate, {
          id: ITEM_COLUMN_KEYS.LAST_MODIFIED_TIMESTAMP,
          header: t('item.modified'),
          cell: props => {
            return (
              <div className="w-full truncate text-pm-black">{formatDate('ShortenedAllDay')(props.getValue())}</div>
            )
          },
          meta: { size: 90 },
        }),
      ],
      [
        dispatch,
        displayProject,
        handleSelectAllItems,
        items,
        multipleSelectedItemIds,
        narrow,
        onClickIcon,
        showOwnerView,
        t,
      ]
    )

    const table = useReactTable({
      data: rows,
      columns,
      getRowId: originalRow => `${originalRow.id ?? itemHelper.getId(originalRow)}`,
      getCoreRowModel: getCoreRowModel(),
      state: {
        columnVisibility: {
          [ITEM_COLUMN_KEYS.SELECT]: multipleSelection,
          [ITEM_COLUMN_KEYS.QUADRANT]:
            showQuadrant && (showQuadrantColumn === 'responsive' ? !narrow : showQuadrantColumn),
          [ITEM_COLUMN_KEYS.ICON]: showIconColumn === 'responsive' ? !narrow : showIconColumn,
          [ITEM_COLUMN_KEYS.OWNER]: showOwner && showOwnerColumn,
          [ITEM_COLUMN_KEYS.PROGRESS]: showProgressColumn === 'responsive' ? !narrow : showProgressColumn,
          [ITEM_COLUMN_KEYS.LAST_MODIFIED_TIMESTAMP]:
            showLastModifiedColumn === 'responsive' ? !narrow : showLastModifiedColumn,
          [ITEM_COLUMN_KEYS.START_DATE]: showStartDateColumn,
          [ITEM_COLUMN_KEYS.DUE_DATE]: showDueDateColumn,
          [ITEM_COLUMN_KEYS.EFFORT]: showEffortColumn,
          [ITEM_COLUMN_KEYS.COMPLETION_DATE]: showCompletionDateColumn,
          [ITEM_COLUMN_KEYS.CREATOR]: showCreatorColumn,
          [ITEM_COLUMN_KEYS.CREATION_DATE]: showCreationDateColumn,
        },
      },
    })
    const { rows: tableRows } = table.getRowModel()

    if (!rows.length && !loading) {
      return (
        <Suspense fallback={<Loading />}>
          <LazyIViewPlaceholder {...placeholder} />
        </Suspense>
      )
    }

    return (
      <>
        <TableVirtuoso
          className="h-full w-full"
          context={{ selectedItem, onRowClick: handleRowClick, handleShowContextualMenu }}
          components={{
            Table: CustomTable,
            TableBody,
            TableRow: CustomTableRow,
            TableHead: CustomTableHead,
          }}
          fixedHeaderContent={() => {
            return table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id} className="!border-none">
                {headerGroup.headers.map(header => {
                  if (header.column.columnDef.meta?.bypassDefaultCell) {
                    return flexRender(header.column.columnDef.header, header.getContext())
                  }
                  return (
                    <TableHeaderCell
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.column.columnDef.meta?.size, overflow: 'hidden' }}
                      sortDirection={
                        sortBy === header.column.id
                          ? sortDirection === SortDirection.ASC
                            ? 'ascending'
                            : 'descending'
                          : undefined
                      }
                      onClick={() => handleSortChange({ sortBy: header.column.id })}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHeaderCell>
                  )
                })}
              </TableRow>
            ))
          }}
          data={tableRows}
          increaseViewportBy={300}
          endReached={endReached}
          computeItemKey={(_, row) => row.id}
        />
        {showContextualMenu && (
          <ItemContextualMenu
            itemId={itemHelper.getId(contextualMenuItem)}
            target={contextualMenuTarget}
            onOpenChange={(ev, data) => setShowContextualMenu(data.open)}
            allItemsInList={items}
          />
        )}
        {!mobilePlatform && (
          <OwnerPanelComponent item={actionItem} isOpenOwnerView={isOpenOwnerView} hideOwnerView={hideOwnerView} />
        )}
      </>
    )
  }
)

const CustomTableRow = ({ item: row, context: { selectedItem, onRowClick, handleShowContextualMenu }, ...rest }) => {
  const selected =
    selectedItem && Map.isMap(row.original) && itemHelper.getId(selectedItem) === itemHelper.getId(row.original)
  const handleClick = useCallback(event => onRowClick?.(event, row.original), [row.original, onRowClick])
  const handleContextMenu = useCallback(
    ev => handleShowContextualMenu({ event: ev, rowData: row.original }),
    [handleShowContextualMenu, row.original]
  )
  const handleMouseDown = useCallback(event => event.preventDefault(), [])
  if (row.original.render) {
    return row.original.render({ row, ...rest })
  }
  return (
    <TableRow
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      onMouseDown={handleMouseDown}
      aria-selected={selected}
      appearance={selected ? 'neutral' : 'none'}
      className="!border-none"
      {...rest}
    >
      {row.getVisibleCells().map(cell => {
        if (cell.column.columnDef.meta?.bypassDefaultCell) {
          return flexRender(cell.column.columnDef.cell, cell.getContext())
        }
        return (
          <TableCell key={cell.id} style={{ width: cell.column.columnDef.meta?.size, overflow: 'hidden' }}>
            {row.id.startsWith('skeleton') ? (
              <Skeleton>
                <SkeletonItem />
              </Skeleton>
            ) : (
              flexRender(cell.column.columnDef.cell, cell.getContext())
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const CustomTable = forwardRef((props, ref) => <Table {...props} ref={ref} sortable />)

const CustomTableHead = forwardRef((props, ref) => {
  return <TableHeader {...props} ref={ref} className="bg-pm-white" />
})
