import React, { memo, Suspense, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { List } from 'immutable'
import { ItemVirtualizedTable } from '../../components/table/ItemVirtualizedTable'
import { Loading } from '../../components/basic/Loading'
import { DefaultButton } from '@fluentui/react/lib/Button'
import { setDefaultFilterItems } from '../../common/src/actions/filtersActions'
import { FILTER_REDUCER_KEYS, ITEM_FILTER_SECTION_KEY } from '../../common/src/reducers/filtersKeys'
import { isFilteringItems } from '../../common/src/selectors/filtersSelectors'
import { BottomCenterArchived } from '../../components/basic/Archived'
import { useTranslation } from 'react-i18next'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  flex: 1;
`
const filtersType = FILTER_REDUCER_KEYS.PROJECT_LIST
export const MatrixTableComponent = memo(({ items, selectedItem, onSelectItem, sortFn, loading, showOwner = true }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isFiltering = useSelector(state => isFilteringItems(state, filtersType))

  const placeholder = useMemo(() => {
    if (isFiltering) {
      return {
        title: t('item.filtering_placeholder.title'),
        message: t('item.filtering_placeholder.message'),
        type: IVIEW_PLACEHOLDER_TYPES.SEARCH,
      }
    }
    return {
      title: t('project.empty_placeholder.title'),
      message: t('project.empty_placeholder.message'),
      type: IVIEW_PLACEHOLDER_TYPES.NOT_FOUND,
    }
  }, [isFiltering, t])
  const noRowsRenderer = useCallback(() => {
    const onClick = () => dispatch(setDefaultFilterItems(ITEM_FILTER_SECTION_KEY.PROJECT_LIST))
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder}>
          <DefaultButton onClick={onClick}>{t('project_filters.clear_title')}</DefaultButton>
        </LazyIViewPlaceholder>
      </Suspense>
    )
  }, [dispatch, placeholder, t])

  const itemsArray = useMemo(() => {
    return (items || List()).sort(sortFn).toArray()
  }, [items, sortFn])

  return (
    <Container>
      <ItemVirtualizedTable
        items={itemsArray}
        selectedItem={selectedItem}
        onRowClick={onSelectItem}
        noRowsRenderer={noRowsRenderer}
        loading={loading}
        isSortEnabled
        showQuadrant
        filterMode={FILTER_REDUCER_KEYS.PROJECT_LIST}
        showOwner={showOwner}
      />
      <BottomCenterArchived />
    </Container>
  )
})
