import React, { useRef, Suspense, memo } from 'react'
import { Loading } from '../../components/basic/Loading'
import { List } from 'immutable'
import { MainLayout } from '../../components/layout/MainLayout'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { ProjectLevelTopBar } from '../project/ProjectLevelTopBar'

const PMCalendar = React.lazy(() => import('./PMCalendar').then(m => ({ default: m.PMCalendar })))

export const PMCalendarView = memo(({ project, items, projectSelectorEnabled, readOnly }) => {
  const mainRef = useRef()
  const p = React.useMemo(() => (project ? List([project]) : undefined), [project])
  const main = (
    <Suspense fallback={<Loading />}>
      <PMCalendar ref={mainRef} items={items} projects={p} readOnly={readOnly} />
    </Suspense>
  )
  const components = {
    main,
    topBar: (
      <ProjectLevelTopBar
        getNavigationURLForProjectId={getRelativeURLKeepingQuerySearch.calendarForProjectId}
        key={'ProjectMatrixViewTopBar'}
        project={project}
        projectSelectorEnabled={projectSelectorEnabled}
        readOnly={readOnly}
        items={items}
        printMain={() => mainRef.current?.print?.()}
      />
    ),
    rightSide: <RightDetailView readOnly={readOnly} />,
  }
  return <MainLayout {...components} />
})
