import React from 'react'
import { ItemsFiltersView } from './ItemsFiltersView'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { ITEMS_SORT_TYPE } from '../../common/src/actions/filtersActions'

const sortTypeBlacklist = [ITEMS_SORT_TYPE.INDEX]

export const SearchFiltersView = ({ filtersType = FILTER_REDUCER_KEYS.SEARCH, ...rest }) => {
  return (
    <ItemsFiltersView
      filtersType={filtersType}
      sortTypeBlacklist={sortTypeBlacklist}
      showSelectColumnsButton
      {...rest}
    />
  )
}
