import React from 'react'
import { Text } from '../text/Text'
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths'
import styled from 'styled-components'
import { FlexColumn } from '../layout/FlexContainer'

const InfoContainer = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  height: 100%;
`

const InfoAligner = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  margin: 24px;

  padding: 0.8em 1.5em 0.8em;

  background: ${p => p.theme.palette.neutralLighter};
  border-radius: 3px;
  box-shadow: ${Depths.depth16};
`

const InfoTitle = styled(Text).attrs({
  variant: 'xLarge',
})`
  padding-bottom: 0.8em;
`

const InfoSubtitle = styled(Text).attrs({
  variant: 'medium',
})`
  padding-bottom: 1.5em;
`

const InfoButtonsContainer = styled.div``

const SupportInfo = styled.div`
  align-self: start;
  margin-top: 10px;
`

const SupportInfoTitle = styled(Text).attrs({
  variant: 'small',
})`
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
`

const SupportInfoLine = styled(Text).attrs({
  variant: 'xSmall',
  block: true,
})`
  margin: 0;
`

export const InfoController = ({ title, subtitle, buttons, supportInfo, ...rest }) => {
  return (
    <InfoContainer {...rest}>
      <InfoAligner>
        <InfoTitle>{title}</InfoTitle>
        {subtitle && <InfoSubtitle>{subtitle}</InfoSubtitle>}
        {buttons && <InfoButtonsContainer>{buttons}</InfoButtonsContainer>}
        {supportInfo && (
          <SupportInfo>
            <SupportInfoTitle>Support info:</SupportInfoTitle>
            {Object.keys(supportInfo).map(key => (
              <SupportInfoLine key={key}>
                {key}: {supportInfo[key]}
              </SupportInfoLine>
            ))}
          </SupportInfo>
        )}
      </InfoAligner>
    </InfoContainer>
  )
}
