import { useMemo } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { PMLogo } from '../Logo'
import { DirectionalHint } from '@fluentui/react/lib/Callout'
import { LeftPanelButton } from './LeftPanelButton'
import { LeftPanelUserButton } from './LeftPanelUserButton'
import { UpgradeRibbon } from '../ribbon/UpgradeRibbon'
import { LeftPanelPendingRequestsButton } from './LeftPanelPendingRequestsButton'
import { LeftPanelNewProjectButton } from './LeftPanelNewProjectButton'
import { CUSTOM_ICONS } from '../../icons/customIcons'
import { ICON_NAMES } from '../../common/src/msIcons'
import * as styleHelper from '../../helpers/styleHelper'
import { generateTrackingHandler, toWithParams } from '../../utils/NavPanelCommon'
import { useDispatch } from 'react-redux'
import { useAttentionNeeded } from '../../hooks/useAttentionNeeded'
import { ROUTE_ID } from '../../routes/routeIdList'
import { useCreateOnboardingStep } from '../../hooks/onboardingHooks'
import { OnboardingStepKeys } from '../../actions/onboardingActions'
import { OnboardingCoachmark } from '../onboarding/OnboardingCoachmark'
import { useResizeDetector } from 'react-resize-detector'
import { useExtendedHistory } from '../../hooks/useExtendedHistory'
import { useTranslation } from 'react-i18next'
import { EVENT_EXTRA, AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { SEARCH_KEYS } from '../../constants/shortcutKeys'
import { SERVER_URLS } from '../../common/src/constants'
import { FlexColumn } from '../layout/FlexContainer'
import { openPurchaseExperienceAction } from '../../utils/teams'
import { useFastProjectsMenu } from './useFastProjectsMenu'
import { useRouteId } from '@/hooks/useRouteId'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

const LeftPanel = styled(FlexColumn)`
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  background: ${p => p.theme.palette.always_primary};
  overflow: hidden;
  width: 50px;

  ${styleHelper.defaultMediaQueries.smallDevices`
    width: 40px;
  `}
`

const Logo = styled(PMLogo)`
  box-sizing: border-box;
  width: 50px;
  padding: 8px;

  ${styleHelper.defaultMediaQueries.smallDevices`
    width: 40px;
  `}
`

const LogoButton = ({ to }) => (
  <LeftPanelButton to={to} tooltipPadding="0 8px">
    <Logo size="squared48" />
  </LeftPanelButton>
)

const LeftPanelSection = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  box-sizing: content-box;

  ${styleHelper.defaultMediaQueries.smallDevices`
    align-items: center;
  `}
`

const isSelectedProjects = routeID =>
  routeID === ROUTE_ID.PROJECTS ||
  routeID === ROUTE_ID.MATRIX_APP ||
  routeID === ROUTE_ID.CALENDAR_APP ||
  routeID === ROUTE_ID.GANTT_APP ||
  routeID === ROUTE_ID.FEED_APP

export const LeftPanelComponent = () => {
  const dispatch = useDispatch()
  const routeId = useRouteId()
  const trackingHandler = useMemo(() => {
    return generateTrackingHandler(dispatch)
  }, [dispatch])
  const { count } = useAttentionNeeded()
  const newProjectButtonOnboardingStep = useCreateOnboardingStep(OnboardingStepKeys.CREATE_FIRST_PROJECT)
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()
  const { t } = useTranslation()
  const { history } = useExtendedHistory()
  const to = toWithParams()
  const projectsMenu = useFastProjectsMenu({
    onAfterClickForProject: () => {
      dispatch(
        dispatchEvent(AMPLITUDE_ACTION_TYPES.CLICK_PROJECTS_LEFT_MENU_OPTION, {
          mode: EVENT_EXTRA.CLICK_PROJECTS_LEFT_MENU_OPTION.MODE.OPEN_RECENT_PROJECT,
        })
      )
    },
    onAfterCreateProject: () => {
      dispatch(
        dispatchEvent(AMPLITUDE_ACTION_TYPES.CLICK_PROJECTS_LEFT_MENU_OPTION, {
          mode: EVENT_EXTRA.CLICK_PROJECTS_LEFT_MENU_OPTION.MODE.CREATE_PROJECT,
        })
      )
    },
    onAfterShowAllProjects: () => {
      dispatch(
        dispatchEvent(AMPLITUDE_ACTION_TYPES.CLICK_PROJECTS_LEFT_MENU_OPTION, {
          mode: EVENT_EXTRA.CLICK_PROJECTS_LEFT_MENU_OPTION.MODE.SHOW_ALL_PROJECTS,
        })
      )
    },
  })

  const arrayOfButtons = [
    {
      id: 'leftPanelComponent_upgrade',
      name: t('left_panel.upgrade_name'),
      tooltip: t('left_panel.upgrade_tooltip'),
      iconName: ICON_NAMES.Ribbon,
      onClick: () => {
        dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.LEFT_PANEL))
      },
      selected: false,
      count: ' ',
      hidden: !shouldDisplayUpgrade,
    },
    {
      //should post home in the default bar because it is tracked in the view
      id: 'leftPanelComponent_HomeButton',
      name: t('left_panel.home_button_name'),
      tooltip: t('left_panel.home_button_tooltip'),
      iconName: ICON_NAMES.Home,
      to: to.alertsHome,
      count: count,
      //onClick={trackingHandler.home} tracking inside view
      selected: routeId === ROUTE_ID.HOME,
    },
    {
      id: 'leftPanelComponent_InboxButton',
      name: t('left_panel.inbox_button_name'),
      tooltip: t('left_panel.inbox_button_tooltip'),
      iconName: ICON_NAMES.Inbox,
      to: to.inbox,
      onClick: trackingHandler.inbox,
      selected: routeId === ROUTE_ID.INBOX,
    },
    {
      id: 'leftPanelComponent_projectsButton',
      name: t('left_panel.projects_button_name'),
      tooltip: t('left_panel.projects_button_tooltip'),
      iconName: CUSTOM_ICONS.QUADRANTS,
      selected: isSelectedProjects(routeId),
      menuProps: projectsMenu,
    },
    {
      id: 'leftPanelComponent_oneOnOneButton',
      name: t('left_panel.one_on_one_button_name'),
      tooltip: t('left_panel.one_on_one_button_tooltip'),
      iconName: ICON_NAMES.People,
      to: to.one_on_one,
      onClick: trackingHandler.one_on_one,
      selected: routeId === ROUTE_ID.ONE_ON_ONE_APP,
    },
    {
      id: 'leftPanelComponent_reportsButton',
      name: t('left_panel.reports_button_name'),
      tooltip: t('left_panel.reports_button_tooltip'),
      iconName: ICON_NAMES.ReportDocument,
      menuProps: {
        items: [
          {
            key: 'classic',
            text: t('left_panel.reports_general_button_name'),
            title: t('left_panel.reports_general_button_tooltip'),
            onClick: () => {
              history.push(to.globalReports)
              trackingHandler.reports()
            },
          },
          {
            key: 'workload',
            text: t('left_panel.workload_button_name'),
            title: t('left_panel.workload_button_tooltip'),
            onClick: () => {
              history.push(to.workload)
              trackingHandler.workload()
            },
          },
        ],
      },
      selected: routeId === ROUTE_ID.GLOBAL_REPORTS_APP,
    },
    {
      id: 'leftPanelComponent_calendarButton',
      name: t('left_panel.calendar_button_name'),
      tooltip: t('left_panel.calendar_button_tooltip'),
      iconName: ICON_NAMES.Calendar,
      to: to.globalCalendar,
      onClick: trackingHandler.calendar,
      selected: routeId === ROUTE_ID.GLOBAL_CALENDAR_APP,
    },
    {
      id: 'leftPanelComponent_searchButton',
      name: t('left_panel.search_button_name'),
      tooltip: t('left_panel.search_button_tooltip'),
      iconName: ICON_NAMES.Search,
      to: to.search,
      onClick: trackingHandler.search,
      selected: routeId === ROUTE_ID.SEARCH_APP,
      tooltipKeys: routeId === ROUTE_ID.HOME ? SEARCH_KEYS : undefined,
    },
    {
      id: 'leftPanelComponent_ganttButton',
      name: t('left_panel.gantt_button_name'),
      tooltip: t('left_panel.gantt_button_tooltip'),
      iconName: ICON_NAMES.BookmarksMirrored,
      to: to.globalGantt,
      selected: routeId === ROUTE_ID.GLOBAL_GANTT_APP,
    },
    // If the app is not embedded on teams, gantt button is going to be here
    {
      id: 'leftPanelComponent_feedButton',
      name: t('left_panel.feed_button_name'),
      tooltip: t('left_panel.feed_button_tooltip'),
      iconName: ICON_NAMES.ActivityFeed,
      to: to.globalFeed,
      onClick: trackingHandler.feed,
      selected: routeId === ROUTE_ID.GLOBAL_FEED_APP,
    },
    {
      id: 'leftPanelComponent_webinars',
      name: t('left_panel.webinars_name'),
      tooltip: t('left_panel.webinars_tooltip'),
      iconName: ICON_NAMES.JoinOnlineMeeting,
      external: SERVER_URLS.WEBINARS,
      onClick: trackingHandler.webinars,
      selected: false,
    },
  ].filter(c => !c.hidden)

  return (
    <LeftPanel>
      <LeftPanelSection>
        <LogoButton to={to.alertsHome} />
        <LeftPanelUserButton />
        <UpgradeRibbon />
        <div ref={newProjectButtonOnboardingStep.ref} style={{ width: '100%' }}>
          <LeftPanelNewProjectButton
            id="leftPanelComponent_newProjectButton"
            name={t('left_panel.new_project_button_name')}
            tooltip={t('left_panel.new_project_button_tooltip')}
            iconName={ICON_NAMES.CircleAddition}
          />
          {newProjectButtonOnboardingStep.isVisible && (
            <OnboardingCoachmark
              stepKey={newProjectButtonOnboardingStep.key}
              target={newProjectButtonOnboardingStep.ref.current}
              onDismiss={newProjectButtonOnboardingStep.hideCoachmark}
              positioningContainerProps={{ directionalHint: DirectionalHint.rightTopEdge }}
            />
          )}
        </div>
        <LeftPanelPendingRequestsButton
          id="leftPanelComponent_pendingInvitationButton"
          name={t('left_panel.pending_invitation_button_name')}
          tooltip={t('left_panel.pending_invitation_button_tooltip')}
          iconName={ICON_NAMES.MailForwardMirrored}
        />
      </LeftPanelSection>
      <DynamicBar futureComponents={arrayOfButtons} />
    </LeftPanel>
  )
}

const DynamicBar = ({ futureComponents }) => {
  const BUTTON_HEIGHT = 54
  const { height, ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  const numberOfRenderizableElements = height / BUTTON_HEIGHT
  const canRenderAll = numberOfRenderizableElements >= futureComponents.length
  const allButtons = _.map(futureComponents, component =>
    !_.isEmpty(component) ? <LeftPanelButton key={component.id} {...component} /> : null
  )

  let content
  if (canRenderAll) {
    content = allButtons
  } else {
    const remaining = allButtons.slice(0, numberOfRenderizableElements - 1)
    const overflowingComponents = futureComponents.slice(numberOfRenderizableElements - 1, futureComponents.length)
    content = (
      <>
        {remaining}
        <MoreItemsButton overflowItems={overflowingComponents} />
      </>
    )
  }

  return <DynamicBarContainer ref={ref}>{content}</DynamicBarContainer>
}

const MoreItemsButton = ({ overflowItems }) => {
  const { history } = useExtendedHistory()
  const { t } = useTranslation()
  const menuItems = _.map(overflowItems, component => ({
    key: component.id,
    text: component.name,
    title: component.tooltip,
    iconProps: {
      iconName: component.iconName,
    },
    count: component.count,
    subMenuProps: component.menuProps,
    canCheck: component.selected,
    checked: component.selected,
    onClick: () => {
      component.onClick?.()
      history.push(component.to)
    },
  }))

  if (!overflowItems) {
    return false
  }

  const moreSelected = overflowItems.some(item => item.selected)
  return (
    <LeftPanelButton
      id="leftPanelComponent_moreItemsButton"
      name={t('left_panel.more_button_name')}
      iconName={'More'}
      menuProps={{ items: menuItems }}
      selected={moreSelected}
    />
  )
}

const DynamicBarContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: hidden;
`
