import React from 'react'

/**
 * Usage

 useAsyncEffect(async isMounted => {
  const data = await fetch(`/users/${id}`).then(res => res.json());
  if (!isMounted()) return;
  setUser(data);
}, [id]);

 * Check https://github.com/rauldeheer/use-async-effect
 */
export function useAsyncEffect(effect, destroy, inputs) {
  const hasDestroy = typeof destroy === 'function'

  React.useEffect(
    function () {
      let result,
        mounted = true
      const maybePromise = effect(function () {
        return mounted
      })

      Promise.resolve(maybePromise).then(function (value) {
        result = value
      })

      return function () {
        mounted = false

        if (hasDestroy) {
          destroy(result)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    hasDestroy ? inputs : destroy
  )
}
