import React, { useCallback, useDeferredValue } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { itemHelper, projectHelper, stateHelper, userHelper } from '../../common/src/helpers'
import { useFetch } from '../../common/src/hooks/networkingHooks'
import { projectHandlerInApp as init } from '../../actions/viewInitializationActions'
import { getMatchParameterInteger } from '../../common/src/utils'
import { useSelectedItemId, useSelectedProjectId } from '../../hooks/PMHooks'
import { useAutoSelectProjectEffect } from './ProjectMainViewUtils'
import { ProjectMatrixView } from '../matrix/ProjectMatrixView'
import { PMCalendarView } from '../calendar/PMCalendarView'
import { Feed } from '../feed/Feed'
import {
  canGlobalMode,
  getFiltersTypeByMode,
  getProjectModeFromPathname,
  PROJECT_MODE,
} from '../../utils/projectModesUtils'
import { InvalidObjectComponent, useIsObjectInvalidToBeShown } from '../errorViews/InvalidObjectComponent'
import { useItemSortDescriptor } from '../../common/src/hooks/filtersHooks'
import { useApplyFiltersToItems } from '../../hooks/itemHooks'
import { MatrixTableView } from '../matrixTable/MatrixTableView'
import { GanttView } from '../gantt/v2/GanttView'
import { PMProjectAnalyticsReport } from '../reports/PMProjectAnalyticsReport'
import { useMe } from '../../common/src/hooks/usersHooks.js'

export const ProjectMainViewHandler = ({ match, history, location }) => {
  const me = useMe()
  const dispatch = useDispatch()
  const pid = getMatchParameterInteger(match, 'pid')
  const projectMode = getProjectModeFromPathname(location.pathname)
  const filtersType = getFiltersTypeByMode(projectMode)
  const selectedItemId = useSelectedItemId()
  const project = useSelector(state => stateHelper.getProject(state, pid))
  const projectItems = useSelector(state => stateHelper.getItemsInProject(state, project))
  const items = useApplyFiltersToItems(projectItems, filtersType)
  const hideOwner =
    projectHelper.getOwnersURIs(project)?.size === 1 &&
    items?.every(item => itemHelper.getOwnerUsername(item) === userHelper.getEmail(me))
  const allProjects = useSelector(stateHelper.getAllProjects)

  const sortFnSync = useItemSortDescriptor()
  const sortFn = useDeferredValue(sortFnSync)
  const fetchMethod = useCallback(() => dispatch(init(pid)), [dispatch, pid])
  const { error, loading } = useFetch(fetchMethod)

  const canGlobal = canGlobalMode(projectMode)
  const shouldAutoSelectProject = !canGlobal
  const currentSelectedProjectId = useSelectedProjectId()
  useAutoSelectProjectEffect(shouldAutoSelectProject, pid, currentSelectedProjectId, allProjects, history)
  const isObjectInvalidToBeShown = useIsObjectInvalidToBeShown()

  const shouldCheckError = !canGlobal
  if (shouldCheckError && !loading && isObjectInvalidToBeShown(project, error)) {
    return <InvalidObjectComponent object={project} error={error} />
  }

  const forwardProps = {
    items,
    project,
    selectedItemId,
    sortFn,
    loading,
    showOwner: !hideOwner,
  }

  switch (projectMode) {
    case PROJECT_MODE.CALENDAR:
      return <PMCalendarView {...forwardProps} />
    case PROJECT_MODE.FEED:
      return <Feed {...forwardProps} />
    case PROJECT_MODE.GANTT:
      return <GanttView {...forwardProps} />
    case PROJECT_MODE.LIST:
      return <MatrixTableView {...forwardProps} />
    case PROJECT_MODE.REPORTS:
      forwardProps.items = projectItems
      return <PMProjectAnalyticsReport {...forwardProps} />
    default:
      return <ProjectMatrixView {...forwardProps} />
  }
}
