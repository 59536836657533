import '@mdxeditor/editor/style.css'
import {
  BoldItalicUnderlineToggles,
  CreateLink,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  type MDXEditorMethods,
  quotePlugin,
  Separator,
  toolbarPlugin,
} from '@mdxeditor/editor'
import { useEffect, useRef } from 'react'
import './projectNotesEditor.css'

export type ProjectNotesEditorProps = {
  value?: string
  onChange: (value: string) => void
  readOnly?: boolean
}

export const ProjectNotesEditor = ({ value = '', onChange, readOnly = false }: ProjectNotesEditorProps) => {
  const editorRef = useRef<MDXEditorMethods>(null)
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setMarkdown(value)
    }
  }, [value])
  return (
    <MDXEditor
      ref={editorRef}
      markdown={value}
      onChange={onChange}
      readOnly={readOnly}
      className={`max-h-48 min-h-12 overflow-auto pb-2 focus:outline-none`}
      contentEditableClassName="!py-1 [&_p]:my-1 [&_a]:no-underline [&_a]:!text-blue-500"
      plugins={[
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <BoldItalicUnderlineToggles />
              <Separator />
              <ListsToggle />
              <Separator />
              <CreateLink />
            </>
          ),
        }),
        headingsPlugin(),
        listsPlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        quotePlugin(),
        markdownShortcutPlugin(),
      ]}
    />
  )
}
