/**
 * Shortcuts to get commonly used variables from global state
 **/
import _ from 'lodash'
import { Iterable, List, Set, Map } from 'immutable'
import { safeMapFnCreator } from './immutableHelpers'
import itemHelper from './itemHelper'
import projectHelper from './projectHelper'
import { PM_API_RESOURCE_TYPE, TRACKED_DESKTOP_PLATFORMS, TRACKED_PLATFORMS } from '../constants'
import { ITEM_KEYS } from '../itemKeys'
import { USERS_KEYS } from '../reducers/userKeys'
import { resourceURICreator } from './URLHelper'
import { usersMapExcludingUsers } from './usersUtils'
import { ITEMS_REDUCER_KEYS } from '../reducers/itemsReducerKeys'
import { SHARING_REQUESTS_KEYS } from '../reducers/sharingRequestsKeys'
import { ASSOCIATED_LINKS_REDUCER_KEYS } from '../reducers/associatedLinksReducerKeys'
import { createSelector } from 'reselect'

let helper = {}

helper.getAllProjects = state => {
  return state.getIn(['projects', 'byId'])
}

helper.getProject = (state, idd) => {
  if (!idd || !_.isNumber(idd)) {
    return void 0
  }
  return state.getIn(['projects', 'byId', idd])
}

const isAccessDeniedTo = (state, id, element) => {
  if (!id || !_.isNumber(id)) {
    return void 0
  }
  return state.getIn([element, 'accessDenied', id])
}
helper.isAccessDeniedToProject = _.partial(isAccessDeniedTo, _, _, 'projects')
helper.isAccessDeniedToItem = _.partial(isAccessDeniedTo, _, _, 'items')

helper.getItem = (state, id) => {
  if (!_.isNumber(id)) {
    return void 0
  }
  return state.getIn(['items', 'byId', id])
}

helper.isModifiedItem = (state, id) => {
  if (!_.isNumber(id)) {
    return void 0
  }
  return !!state.getIn(['items', 'modified', id])
}

helper.getAllItemsMap = state => state.getIn(['items', 'byId'])

helper.getItemsFromIDs = createSelector(
  helper.getAllItemsMap,
  (state, iterator) => iterator,
  (allItems, iterator) => {
    if (!Iterable.isIterable(iterator)) {
      return
    }
    return iterator.map(id => allItems.get(id)).filter(item => !!item)
  }
)

helper.getProjectsFromIDs = (state, iterator) => {
  if (!Iterable.isIterable(iterator)) {
    return
  }
  return iterator.map(id => helper.getProject(state, id)).filter(project => !!project)
}

/**
 * Returns List of items in project
 */
helper.getItemsInProject = createSelector(
  helper.getAllItemsMap,
  (state, project) => project,
  (allItems, project) => {
    const projectResourceUri = projectHelper.isProject(project) && projectHelper.getResourceURI(project)
    if (!projectResourceUri) {
      return void 0
    }
    const itemBelongsToThisProject = _.partial(itemHelper.belongsToProject, _, projectResourceUri) // _ is a wildcard
    return allItems.filter(itemBelongsToThisProject).toList()
  }
)

helper.getRelatedItemsSet = (state, itemID) => {
  return state.getIn(['items', ITEMS_REDUCER_KEYS.BY_ID, itemID, ITEM_KEYS.RELATED_ITEMS]) || Set()
}

helper.getRelatedItems = (state, itemID) => {
  const set = helper.getRelatedItemsSet(state, itemID)
  return helper.getItemsFromIDs(state, set)
}

helper.getAccessToken = state => state.getIn(['auth', 'access_token'])

helper.getCollaboratorsMap = state => state.getIn([USERS_KEYS.REDUCER, USERS_KEYS.COLLABORATORS])
helper.getGraphCollaboratorsMap = state => state.getIn([USERS_KEYS.REDUCER, USERS_KEYS.GRAPH_COLLABORATORS])
helper.getTeamsCollaboratorsMap = state => state.getIn([USERS_KEYS.REDUCER, USERS_KEYS.TEAMS_MEMBERS])
helper.getUserWithEmail = (state, email) => {
  return state.getIn([USERS_KEYS.REDUCER, USERS_KEYS.COLLABORATORS, email])
}
helper.getGraphCollaboratorWithEmail = (state, email) =>
  state.getIn([USERS_KEYS.REDUCER, USERS_KEYS.GRAPH_COLLABORATORS, email])

helper.getGraphCollaboratorsMapById = state => {
  const graphCollaborators = helper.getGraphCollaboratorsMap(state)
  let graphCollaboratorsMap = Map()
  graphCollaborators.forEach(collaborator => {
    graphCollaboratorsMap = graphCollaboratorsMap.set(
      collaborator.get(USERS_KEYS.GRAPH_PROFILE).get(USERS_KEYS.ID),
      collaborator.get(USERS_KEYS.EMAIL)
    )
  })
  return graphCollaboratorsMap
}

helper.getMe = state => state.getIn([USERS_KEYS.REDUCER, USERS_KEYS.ME])
helper.getMeEmail = state => state.getIn([USERS_KEYS.REDUCER, USERS_KEYS.ME, USERS_KEYS.EMAIL])
helper.isAppfluenceCurrentUser = state => {
  const meEmail = helper.getMeEmail(state)
  return _.includes(meEmail, '@appfluence')
}

/**
 * Returns collaborators without me
 */
helper.getOtherCollaborators = state => {
  const collaborators = helper.getCollaboratorsMap(state)
  const me = helper.getMe(state)
  return usersMapExcludingUsers(collaborators, me)
}

/**
 * Returns ms collaborators without me
 */
helper.getTeamsCollaborators = state => {
  const graphCollaborators = helper.getTeamsCollaboratorsMap(state)
  const me = helper.getMe(state)
  return usersMapExcludingUsers(graphCollaborators, me)
}

/**
 * Returns collaborators from Graph without me
 */
helper.getGraphCollaborators = state => {
  const graphCollaborators = helper.getGraphCollaboratorsMap(state)
  const me = helper.getMe(state)
  return usersMapExcludingUsers(graphCollaborators, me)
}

helper.getSharingRequestsMap = state => {
  return state.getIn([SHARING_REQUESTS_KEYS.sharingRequests, SHARING_REQUESTS_KEYS.byProjectID])
}

helper.getSharingRequestsMapForProject = (state, project) => {
  const projectID = projectHelper.getIdd(project)
  const sharingRequestsMap = helper.getSharingRequestsMap(state)
  return sharingRequestsMap.get(projectID)
}

/**
 * @param comparator Check https://devdocs.io/immutable/index#map.sort
 */
helper.getFirstItemsSortedBy = (state, n, comparator) => {
  const allItems = helper.getAllItemsMap(state)
  return allItems.sort(comparator).take(n)
}

helper.getInboxPlusItems = createSelector(
  state => state.getIn(['items', ITEMS_REDUCER_KEYS.INBOX_PLUS]),
  helper.getAllItemsMap,
  helper.getAllProjects,
  (inboxItemSet, allItemsMap, projectsMap) => {
    // We will add items from external projects
    const inboxPlusSet = allItemsMap
      .filter(item => isInboxPlusItem(projectsMap, item))
      .keySeq()
      .toSet()
    const allSet = inboxItemSet.concat(inboxPlusSet)
    const items = allSet.map(id => allItemsMap.get(id)).filter(item => !!item)
    return items.filter(item => !itemHelper.isDeleted(item))
  }
)

const isInboxPlusItem = (allProjects, item) => {
  const id = itemHelper.getId(item)
  if (!id || id <= 0) {
    return false
  }
  const projectID = itemHelper.getProjectIdd(item)

  // Normal Inbox
  if (projectID <= 0) {
    return false
  }

  // Plus Inbox
  // It fails if whole "inbox plus" was not downloaded
  //const itemSet = state.getIn(['items', 'inboxPlus'])
  //return itemSet.some(itemID => itemID === id)
  // Using project list is more reliable because this list is downloaded usually.
  return !allProjects.has(projectID)
}

helper.isInboxPlusItem = (state, item) => isInboxPlusItem(helper.getAllProjects(state), item)

helper.getNewItemMovingItemTo = (state, item, project, quadrant) => {
  const id = itemHelper.getId(item)
  const oldProjectID = itemHelper.getProjectIdd(item)
  const newProjectID = projectHelper.getIdd(project)
  let modifiedItem =
    quadrant === -1
      ? item
      : item.withMutations(ctx => {
          ctx.set(itemHelper.KEYS.QUADRANT, quadrant)
        })
  let isCopy = false
  let deleteOld = false
  if (project !== 'keep' && oldProjectID !== newProjectID) {
    if (newProjectID) {
      const projectURI = resourceURICreator(1, PM_API_RESOURCE_TYPE.PROJECT, newProjectID)
      modifiedItem = modifiedItem.set(itemHelper.KEYS.PROJECTS, [projectURI])
    } else {
      modifiedItem = modifiedItem.set(itemHelper.KEYS.PROJECTS, [])
    }
    const isInbox = itemHelper.isInbox(item)
    const isPlus = helper.isInboxPlusItem(state, item)
    isCopy = !isInbox || isPlus
    deleteOld = isCopy && !isPlus
    if (isCopy) {
      modifiedItem = modifiedItem.withMutations(ctx => {
        ctx.set(itemHelper.KEYS.COPIED_FROM_ID, id)
        ctx.delete(itemHelper.KEYS.ID)
        ctx.delete(itemHelper.KEYS.VERSION_ID)
        ctx.delete(itemHelper.KEYS.RESOURCE_URI)
        ctx.delete(itemHelper.KEYS.LAST_ATTENTION)
        ctx.delete(itemHelper.KEYS.LAST_READ)
        ctx.delete('recurrence')
      })
    }
  }
  return { newItem: modifiedItem, isCopy, deleteOld: deleteOld }
}

//Maybe we should cache needsAttention too (let's test it later)
helper.getAttentionNeededItems = createSelector(helper.getMeEmail, helper.getAllItemsMap, (email, allItems) => {
  if (!email) {
    return List()
  }
  return allItems.filter(i => itemHelper.needsAttention(i, email))
})

helper.getAttentionNeededIdSetInCurrentSession = state => {
  return state.getIn(['items', ITEMS_REDUCER_KEYS.ATTENTION_NEEDED])
}

helper.getProjectsWith = (state, collaboratorEmail) => {
  const user = helper.getUserWithEmail(state, collaboratorEmail)
  if (!user) {
    return List()
  }
  const uri = user.get('resource_uri')
  const all = helper.getAllProjects(state)
  const _filterFn = _.partial(projectHelper.isOwner, _, uri)
  return all.filter(_filterFn)
}

helper.getSuggestedIndexForNewItem = (state, projectId, quadrant) => {
  const project = helper.getProject(state, projectId)
  const projectItems = helper.getItemsInProject(state, project)
  const quadrantItems = projectItems.filter(item => itemHelper.getQuadrant(item) === quadrant)
  return (
    quadrantItems.reduce((best, item) => {
      const index = itemHelper.getIndex(item)
      return index > best ? index : best
    }, 0) + 1000
  )
}

helper.getPlatforms = state => state.getIn(['users', 'platforms'])

const _matchDesktopPlatforms = _.memoize(platformList => {
  const platformSet = platformList.toSet()
  const desktopPlatformSet = Set(TRACKED_DESKTOP_PLATFORMS)
  const match = desktopPlatformSet.intersect(platformSet)
  return !!match.size
})

const _matchDTeamsPlatform = _.memoize(platformList => {
  const platformSet = platformList.toSet()
  const desktopPlatformSet = Set([TRACKED_PLATFORMS.TEAMS])
  const match = desktopPlatformSet.intersect(platformSet)
  return !!match.size
})

const _matchDOutlookPlatform = _.memoize(platformList => {
  const platformSet = platformList.toSet()
  const desktopPlatformSet = Set([TRACKED_PLATFORMS.OUTLOOK365, TRACKED_PLATFORMS.OUTLOOK_MOBILE])
  const match = desktopPlatformSet.intersect(platformSet)
  return !!match.size
})

helper.userHasInstalledDesktopApps = state => {
  const platforms = helper.getPlatforms(state)
  if (List.isList(platforms) && platforms.size) {
    return _matchDesktopPlatforms(platforms)
  }
  return false
}

helper.userHasInstalledTeamsIntegration = state => {
  const platforms = helper.getPlatforms(state)
  if (List.isList(platforms) && platforms.size) {
    return _matchDTeamsPlatform(platforms)
  }
  return false
}

helper.userHasInstalledOutlookAddin = state => {
  const platforms = helper.getPlatforms(state)
  if (List.isList(platforms) && platforms.size) {
    return _matchDOutlookPlatform(platforms)
  }
  return false
}

helper.currentUserIsPaying = state => {
  const me = helper.getMe(state)
  if (me) {
    // I've found an odd situation here while I was updating cypress tests.
    // Sometimes on app initialization 'me' exists, but it's not an immutable object
    // Let's track this since it might be our initialization issue
    // By Paolo
    try {
      const single = me.get('paying_single')
      const team = me.get('paying_team')
      return single || team
    } catch (err) {}
  }
  return true
}

helper.currentUserDateJoined = state => {
  const me = helper.getMe(state)
  return me ? me.get('date_joined') : 0
}

helper.getHumanizedProjectNameForItem = (state, item) => {
  const projectIdd = itemHelper.getProjectIdd(item)
  if (projectIdd === 0) {
    return 'Inbox'
  }
  const project = helper.getProject(state, projectIdd)
  if (!project) {
    return 'External Project'
  }
  return projectHelper.getName(project)
}

helper.getAssociatedLinksForItem = (state, itemID) => {
  return state.getIn([ASSOCIATED_LINKS_REDUCER_KEYS.REDUCER, ASSOCIATED_LINKS_REDUCER_KEYS.BY_ID, itemID]) || List()
}

helper = _.mapValues(helper, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))
export default helper
