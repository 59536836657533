import { useAttentionNeededInSession } from '../../common/src/hooks/itemHooks'
import { useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '../../common/src/helpers'
import { useMemo } from 'react'
import _ from 'lodash'

const compareItemsByMentionAndTimestamp = (a, b) => {
  // Items with unread mentions go first
  const aUnreadMention = itemHelper.hasUnreadMention(a)
  const bUnreadMention = itemHelper.hasUnreadMention(b)
  if (aUnreadMention && !bUnreadMention) return -1
  if (!aUnreadMention && bUnreadMention) return 1

  // Latest modified items go first
  const aTimestamp = itemHelper.getLastModifiedTimestamp(a)
  const bTimestamp = itemHelper.getLastModifiedTimestamp(b)
  if (aTimestamp > bTimestamp) return -1
  if (aTimestamp < bTimestamp) return 1
  return 0
}

export const useAlertItems = forceAlertId => {
  const alerts = useAttentionNeededInSession()
  const forced = useSelector(st => stateHelper.getItem(st, forceAlertId))
  const list = forced ? alerts.add(forced) : alerts
  const sorted = useMemo(() => {
    return list.toIndexedSeq().sort(compareItemsByMentionAndTimestamp).toArray()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.size])

  return useMemo(() => {
    return _.map(sorted, item => {
      const id = itemHelper.getId(item)
      const newItem = list.find(iterItem => itemHelper.getId(iterItem) === id)
      return newItem || item
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorted, list.hashCode()])
}
