import { useMemo } from 'react'
import { List } from 'immutable'
import _ from 'lodash'
import { createOrganizedUsersOptions } from '../helpers/usersOptionsHelpers'
import {
  useGraphCollaboratorsMapMinusMe,
  useMe,
  useNoTeamMap,
  useProjectMembersForProjectMap,
  useTeamMap,
  useTeamsCollaboratorsMapMinusMe,
} from '../common/src/hooks/usersHooks'
import { usersMapExcludingUsers } from '../common/src/helpers/usersUtils'
import { projectHelper, userHelper } from '../common/src/helpers'
import { isEmbeddedOnTeams } from '../helpers/queryParamsHelper'
import { useTranslation } from 'react-i18next'

export const useOrganizedUsersOptions = ({
  project,
  minusUsers = [],
  withNoOwner = false,
  noOwnerText,
  addDivider = false,
  sortDescriptor = userHelper.sortDescriptorByFullName,
} = {}) => {
  const projectMembersMap = useProjectMembersForProjectMap({ project })
  const teamMap = useTeamMap()
  const noTeamMap = useNoTeamMap()

  const teamsMap = useTeamsCollaboratorsMapMinusMe()
  const graphCollaboratorsMap = useGraphCollaboratorsMapMinusMe()
  const me = useMe()
  const { t } = useTranslation()

  return useMemo(() => {
    const projectName = projectHelper.getName(project)
    const sanitizedProjectMembersMap = usersMapExcludingUsers(projectMembersMap, me, minusUsers)
    const sortedSanitizedProjectMembersMap = sanitizedProjectMembersMap.sortBy(sortDescriptor)
    const sortedSanitizedProjectMembers = sortedSanitizedProjectMembersMap.toArray()

    const sanitizedTeamMap = usersMapExcludingUsers(teamMap, sortedSanitizedProjectMembers, me, minusUsers)
    const sortedTeam = sanitizedTeamMap.sortBy(sortDescriptor).toArray()

    const onlyGraphAndPMMap = graphCollaboratorsMap.merge(noTeamMap)
    const graphAndPMMap = isEmbeddedOnTeams() ? teamsMap.merge(onlyGraphAndPMMap) : onlyGraphAndPMMap
    const sanitizedGraphAndPMMap = usersMapExcludingUsers(
      graphAndPMMap,
      sortedSanitizedProjectMembers,
      sortedTeam,
      me,
      minusUsers
    )
    const sortedSanitizedGraphAndPMMap = sanitizedGraphAndPMMap.sortBy(sortDescriptor)

    const groupByIsPMUser = sortedSanitizedGraphAndPMMap.groupBy(user => userHelper.isPMUser(user))
    const sortedOthers = groupByIsPMUser.get(true, List()).toArray()
    const noPM = groupByIsPMUser.get(false, List()).toArray()

    const meEmail = userHelper.getEmail(me)
    const withMe = !_.find(minusUsers, user => userHelper.getEmail(user) === meEmail)

    return createOrganizedUsersOptions({
      projectName,
      projectMembers: sortedSanitizedProjectMembers,
      team: sortedTeam,
      otherUsers: sortedOthers,
      noPM,
      me: withMe ? me : undefined,
      withNoOwner,
      noOwnerText,
      addDivider,
      t,
    })
  }, [
    project,
    projectMembersMap,
    me,
    minusUsers,
    sortDescriptor,
    teamMap,
    graphCollaboratorsMap,
    noTeamMap,
    teamsMap,
    withNoOwner,
    noOwnerText,
    addDivider,
    t,
  ])
}
