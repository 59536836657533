import React, { useCallback } from 'react'
import { cn } from '@appfluence/classnames'
import styled from 'styled-components'
import { PointerWithOnClick } from '../../style/reusableStyledCss'
import { Icon } from '@fluentui/react/lib/Icon'
import { ICON_NAMES } from '../../common/src/msIcons'
import { useSelector } from 'react-redux'
import { useRouteId } from '../../hooks/useRouteId'
import { getSelectedMeeting } from '../../common/src/selectors/meetingSelectors'
import { useSelectedItem, useSelectedProject } from '../../hooks/PMHooks'
import { routesWithTitle } from './typedCommonMobileNavBar'
import { useExtendedHistory } from '@/hooks/useExtendedHistory'

const Text = styled.span`
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${PointerWithOnClick}
`

const Arrow = styled(Icon).attrs({
  iconName: ICON_NAMES.ChevronDown,
})`
  font-size: 13px;
  margin-left: 3px;
  ${PointerWithOnClick}
`

export const Title = React.memo(({ name, path, hideArrow }) => {
  const { history } = useExtendedHistory()
  const arrowCN = cn({ pm_hidden: hideArrow })
  const onClick = () => {
    if (path) history.push(path)
  }
  return (
    <>
      <Text onClick={onClick}>{name}</Text>
      <Arrow onClick={onClick} className={arrowCN} />
    </>
  )
})

export const getNavOnClick = history => pathGetter => useCallback(() => history.push(pathGetter()), [pathGetter])

export const Bar = styled.div`
  background: ${p => p.theme.palette.always_primary};
  height: 40px;
  display: flex;
  overflow: hidden;
`

export const Center = styled.div`
  flex: 1;
  min-width: 1px;
  color: ${p => p.theme.palette.always_white};
  display: flex;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
`

export const useCenterGetter = () => {
  const selectedProject = useSelectedProject()
  const selectedItem = useSelectedItem()
  const selectedMeeting = useSelector(getSelectedMeeting)
  const routeId = useRouteId()
  return routesWithTitle({
    item: selectedItem,
    project: selectedProject,
    meeting: selectedMeeting,
  })[routeId]?.()
}
