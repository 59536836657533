import React, { forwardRef, memo, useCallback, useMemo } from 'react'
import { cn } from '@appfluence/classnames'
import styled, { useTheme } from 'styled-components'
import { Icon } from '@fluentui/react/lib/Icon'
import { itemHelper } from '../../common/src/helpers'
import { IconCell } from '../icon/IconCell'
import { ProgressBar } from '../basic/ProgressBar'
import { useUserForEmail } from '../../common/src/hooks/usersHooks'
import { UserCollectionCell } from '../users/usersCollection/UserCollectionCell'
import { IconButton } from '@fluentui/react/lib/Button'
import { FlexColumn, FlexRow } from '../layout/FlexContainer'

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: -4px;
  margin-bottom: -4px;
`

const Name = styled.div`
  display: -webkit-box;
  flex: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

  color: ${p => p.theme.palette.black};
  user-select: none;
  text-decoration: none;

  &.done {
    text-decoration: line-through;
  }

  &.narrow {
    -webkit-line-clamp: 1;
  }
`

const DueText = styled.div`
  margin-left: 32px;
  font-size: 10px;
  color: ${p => (p.color ? p.color : p.theme.palette.neutralDark)};
`

const Center = styled(FlexColumn)`
  flex: 1;
  min-width: 0;
  align-self: center;
`

const TopCenter = styled.div`
  display: flex;
  position: relative;
`

const Star = styled(Icon).attrs({
  iconName: 'FavoriteStarFill',
})`
  color: #ffea00;
  font-size: 90%;
  position: absolute;
  left: -2px;
  top: -2px;
  z-index: 1000;
`

// Right Detail
const RightDetailWidth = 28
const RightDetailHeight = 28
const RightDetailSpace = 4

const ProgressBarWidth = RightDetailWidth
const ProgressBarHeight = 5

const PercentageText = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${p => p.theme.palette.themePrimary};
  margin-bottom: ${RightDetailSpace}px;
  user-select: none;
`

const ProgressBarStyled = styled(ProgressBar).attrs({
  height: ProgressBarHeight,
  width: ProgressBarWidth,
})``

const SIconCell = styled(IconCell)`
  // Make up for item icon margins
  margin-top: 2px;
  margin-bottom: 2px;
`

const RightDetail = styled.div`
  float: right;
  margin-left: 4px;
`

const AddButton = styled(IconButton).attrs(props => ({
  iconProps: { iconName: props.added ? 'CheckMark' : 'Add' },
}))`
  user-select: none;
  &:focus {
    outline: none;
  }

  align-self: flex-start;
  margin-right: 8px;
  color: ${p => p.theme.palette.themePrimary};
  font-size: 16px;
`

// Container
const CellContainer = styled(FlexRow)`
  flex: 1;
  position: relative;
  background: ${p => p.theme.palette.white};
  padding: 8px;
  box-sizing: border-box;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      background: ${p => p.theme.palette.neutralLight};
    }
  }

  &.selected {
    background: ${p => p.theme.palette.themeTertiary};
  }
`

export const ItemCell = memo(
  forwardRef(({ item, onClick, onAdd, selected = false, added = false, narrow = false, ...rest }, ref) => {
    const theme = useTheme()
    const handleClick = useCallback(
      event => {
        // Need to do this in order to avoid
        // triggering doubleClick on quadrant
        event.stopPropagation()
        onClick(item)
      },
      [onClick, item]
    )

    const didAdd = useCallback(
      event => {
        event.stopPropagation()
        onAdd(item)
      },
      [onAdd, item]
    )

    const id = itemHelper.getId(item)
    const name = itemHelper.getName(item)
    const cellClass = cn({ selected })
    const starred = itemHelper.isStarred(item)
    const email = itemHelper.getOwnerUsername(item)
    const owner = useUserForEmail(email)

    const iconName = itemHelper.getIconNameSafelyWithoutExtension(item)
    const hasIcon = iconName !== itemHelper.EMPTY_ICON_WITHOUT_EXTENSION
    const progress = itemHelper.getCompletionPercentage(item)
    const hasProgress = progress > 0

    const dueText = itemHelper.getDueTextFromNow(item)
    const dueColor = itemHelper.getDueColor(item, theme.palette.affirmative, theme.palette.negative)

    const done = itemHelper.isCompleted(item)
    const iconSide = useMemo(() => {
      if (!hasIcon) {
        return 0
      }
      return hasProgress ? RightDetailHeight - ProgressBarHeight : RightDetailHeight
    }, [hasIcon, hasProgress])
    const showPercentageText = useMemo(() => !hasIcon && hasProgress, [hasIcon, hasProgress])

    const narrowClassName = cn({ narrow })
    const textClassName = cn(narrowClassName, { done })
    return (
      <CellContainer onClick={handleClick} className={cellClass} aria-describedby={id} ref={ref} {...rest}>
        <AddButton added={added} onClick={didAdd} />
        <Center>
          <TopCenter>
            {starred && <Star />}
            <UserCollectionCell
              className="mr-2 self-start"
              user={owner}
              personaProps={{
                avatar: { size: 28 },
              }}
              hidePersonaDetails
            />
            <TextContainer>
              <Name className={textClassName}>{name}</Name>
            </TextContainer>
            {!narrow && (hasIcon || hasProgress) && (
              <RightDetail>
                {hasIcon && <SIconCell icon={iconName} side={iconSide} />}
                {showPercentageText && <PercentageText progress={progress}>{`${progress}%`}</PercentageText>}
                {hasProgress && <ProgressBarStyled progress={progress} />}
              </RightDetail>
            )}
          </TopCenter>
          {dueText && <DueText color={dueColor}>{dueText}</DueText>}
        </Center>
      </CellContainer>
    )
  })
)
