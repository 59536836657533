import { PM_API_URLS } from '../common/src/constants'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { dispatchEvent } from '../common/src/eventTracking/amplitudeEvents'
import { EVENTS } from '../common/src/eventTracking/amplitudeEvents'
import { useTranslation } from 'react-i18next'
import { intervalToDuration } from 'date-fns/fp'
import { useFormatDuration } from '../utils/datefns'

export const NOTES_LIMIT_FOR_AI = 1000
const responseStateExceededLimit = (state: number) => state === 429 || state === 402

type AIErrorAPI =
  | {
      max_requests: number
      time_period: number
    }
  | { error: string }

type AIError =
  | {
      reason: 'limit_exceeded'
      maxRequests: number
      periodInSeconds: number
    }
  | { reason: 'other'; message?: string }

export type UseFillNotesWithAIParams = {
  name: string
  currentNotes?: string
  updateNotes: (newNotes: string) => void
  projectName?: string
  projectIdd?: number
  eventTrackingMode?: string
}
export const useFillNotesWithAI = ({
  name,
  currentNotes = '',
  updateNotes,
  projectName,
  projectIdd,
  eventTrackingMode,
}: UseFillNotesWithAIParams) => {
  const formatDuration = useFormatDuration()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AIError | null>(null)

  const handleFillWithAI = async () => {
    setError(null)
    setLoading(true)
    let answer: Response
    try {
      answer = await fetch(
        PM_API_URLS.AI_NOTES +
          '?' +
          new URLSearchParams({
            name,
            descriptionText: currentNotes ?? '',
            ...(projectName && { project_name: projectName }),
            ...(projectIdd && { project_idd: projectIdd.toString() }),
          })
      ).then(res => {
        if (!res.ok) {
          throw res
        }
        return res
      })
    } catch (e) {
      setLoading(false)
      const limitExceeded = responseStateExceededLimit((e as Response)?.status)
      try {
        const json = (await (e as Response).json()) as AIErrorAPI
        if (limitExceeded && 'max_requests' in json) {
          setError({ reason: 'limit_exceeded', maxRequests: json.max_requests, periodInSeconds: json.time_period })
        } else if ('error' in json) {
          setError({ reason: 'other', message: json.error })
        } else {
          setError({ reason: 'other' })
        }
      } catch {
        setError({ reason: 'other' })
      }
      return
    }
    const suggestion = await answer.text()
    const newNotes = currentNotes?.trim() ? `${currentNotes}\n\n${suggestion}` : suggestion
    updateNotes(newNotes)
    setLoading(false)
    setError(null)
    dispatch(dispatchEvent(EVENTS.AI_FILL_NOTES, { mode: eventTrackingMode }))
  }
  const limitExceeded = error?.reason === 'limit_exceeded'

  return {
    loading,
    error: error
      ? {
          title: t(limitExceeded ? 'ai.limit_error.title' : 'error.unknown.title'),
          message: limitExceeded
            ? t('ai.limit_error.message', {
                count: error.maxRequests,
                duration: formatDuration(intervalToDuration({ start: 0, end: error.periodInSeconds * 1000 })),
              })
            : error.message ?? t('error.unknown.message'),
          limitExceeded,
        }
      : null,
    handleFillWithAI,
  }
}

export const AI_SUPPORT_EMAIL = 'helpdesk+ai@appfluence.com'
