import { ICON_NAMES } from '../../common/src/msIcons'
import { useTranslation } from 'react-i18next'
import { Icon } from '@fluentui/react'
import { SERVER_URLS } from '../../common/src/constants'
import { openExternalLink } from '../../utils/externalLinkHandler'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { FolderArrowRight } from '@/components/BundledIcons'
import { Link } from 'react-router-dom'
import { getRelativePathToMatrixBasedOnMode } from '@/helpers/routeHelper'
import { useSelector } from 'react-redux'
import { getPreferredMatrixViewMode } from '@/selectors/uiSelectors'
import { projectHelper, stateHelper } from '@/common/src/helpers'
import { cn } from '@appfluence/classnames'

export const Section = ({ children }) => <div className="flex flex-col px-4">{children}</div>

export const ProjectName = ({ project, disabled = false }) => {
  const { t } = useTranslation()
  const mode = useSelector(getPreferredMatrixViewMode)
  const projectIdd = projectHelper.getIdd(project)
  const accessDeniedToProject = useSelector(state => stateHelper.isAccessDeniedToProject(state, projectIdd))

  const projectName = (() => {
    if (projectIdd) {
      return accessDeniedToProject || !project ? t('project.external_project') : projectHelper.getName(project)
    }
    return 'Inbox'
  })()

  return (
    <Tooltip content={!disabled && t('item_detail.project_detail')} relationship="description">
      <Link
        className={cn(
          'truncate text-black no-underline transition-all hover:text-pm-theme-primary dark:text-white dark:hover:text-pm-theme-primary',
          disabled && 'pointer-events-none'
        )}
        to={getRelativePathToMatrixBasedOnMode(mode)(projectIdd)}
      >
        {projectName}
      </Link>
    </Tooltip>
  )
}

export const MoveProjectButton = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Tooltip content={t('item_detail.move_to_project_action')} relationship="label" positioning="below">
      <Button
        className="!ml-1 !transition-all group-hover:opacity-100 md:opacity-0"
        icon={<FolderArrowRight />}
        onClick={onClick}
        appearance="subtle"
      />
    </Tooltip>
  )
}

export const InboxIcon = () => <Icon iconName={ICON_NAMES.Inbox} className="text-3xl text-pm-theme-primary" />

const onClickFactory = href => () => openExternalLink(href) // Duplicated from accounts.js...

export const VersionControlModal = ({ isOpen, onDismiss, itemID }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(event, data) => {
        if (!data.open) {
          onDismiss()
        }
      }}
    >
      <DialogSurface className="!max-w-4xl">
        <DialogBody>
          <DialogTitle>{t('item_detail.version_control_action')}</DialogTitle>
          <DialogContent>
            <iframe
              className="h-[80vh] w-full border-none"
              title={t('item_detail.version_control_action')}
              src={SERVER_URLS.VERSION_CONTROL + itemID}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClickFactory(SERVER_URLS.VERSION_CONTROL + itemID)()
                onDismiss()
              }}
            >
              {t('item_detail.new_tab_item')}
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button>{t('general.close')}</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
