import {
  DefaultError,
  MutationState,
  useInfiniteQuery,
  useMutation,
  useMutationState,
  useQueryClient,
} from '@tanstack/react-query'
import { appendQueryParamsToURL, getCredentialsConfig } from '../common/src/helpers/requestHelper'
import { useStore } from 'react-redux'
import { resourceURIParser } from '../common/src/helpers/URLHelper'
import { PaginatedResponse } from '../types/apiTypes'
import type { Comment } from '../types/comment'

type ApiResponse = PaginatedResponse<Comment>

export const useCommentsInItem = (itemId: number, { enabled = true }) => {
  const store = useStore()

  return useInfiniteQuery({
    enabled,
    queryKey: ['comments', itemId],
    queryFn: async ({ pageParam }) => {
      const baseUrl = `/api/v1/item/${itemId}/comments/`
      const params: Record<string, any> = { format: 'json', limit: 100, showOriginalText: true }
      if (pageParam) {
        params['timestamp__lt'] = pageParam
      }
      const response = await fetch(appendQueryParamsToURL(baseUrl, params)(store.getState()), {
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as Pick<ApiResponse, 'objects'> & Partial<Pick<ApiResponse, 'meta'>>
    },
    getNextPageParam: lastPage =>
      lastPage.meta?.next ? lastPage.objects[lastPage.objects.length - 1].timestamp : undefined,
    initialPageParam: '',
  })
}

type SendCommentMutationVariables = {
  itemId: number
  commentText: string
}
export type CommentsInItemData = ReturnType<typeof useCommentsInItem>['data']

export const useSendComment = () => {
  const queryClient = useQueryClient()
  const store = useStore()

  return useMutation({
    mutationKey: ['comments', 'send'],
    mutationFn: async ({ itemId, commentText }: SendCommentMutationVariables) => {
      const url = '/api/v1/comment/'
      const params = { nohtml: 1, showOriginalText: true }
      const response = await fetch(appendQueryParamsToURL(url, params)(store.getState()), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ text: commentText, item: `/api/v1/item/${itemId}/` }),
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as Comment
    },
    onSuccess: (comment, variables) => {
      const itemId = variables.itemId
      queryClient.setQueryData<CommentsInItemData>(['comments', itemId], oldData => {
        if (!oldData) return oldData
        return { pages: [{ objects: [comment] }, ...oldData.pages], pageParams: ['', ...oldData.pageParams] }
      })
    },
  })
}

type MyMutationState = MutationState<Comment, DefaultError, SendCommentMutationVariables>
export const useSendCommentStatus = (itemId: number) => {
  return useMutationState<MyMutationState>({
    filters: {
      mutationKey: ['comments', 'send'],
      predicate: mutation => mutation.state.variables?.itemId === itemId,
    },
    select: data => {
      return data.state as MyMutationState
    },
  })
}

export const useEditComment = () => {
  const queryClient = useQueryClient()
  const store = useStore()

  return useMutation({
    mutationKey: ['comments', 'edit'],
    mutationFn: async ({ commentId, text }: { commentId: number; text: string }) => {
      const url = `/api/v1/comment/${commentId}/edit/`
      const params = { nohtml: 1, showOriginalText: true }
      const response = await fetch(appendQueryParamsToURL(url, params)(store.getState()), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ text }),
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as Comment
    },
    onSuccess: comment => {
      const itemResource = resourceURIParser(comment.item)
      if (!itemResource) return
      queryClient.setQueryData<CommentsInItemData>(['comments', itemResource.id], oldData => {
        if (!oldData) return oldData
        const newPages = oldData.pages.map(page => ({
          ...page,
          objects: page.objects.map(oldComment => (oldComment.id === comment.id ? comment : oldComment)),
        }))
        return { pages: newPages, pageParams: oldData.pageParams }
      })
    },
  })
}
