import React, { Suspense, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isPendingRequestForPMResource } from '../../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from '../../common/src/constants'
import { Loading } from '../../components/basic/Loading'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { BasicItemList } from '../../components/item/basicList/BasicItemList'
import { useTranslatedPlaceholders } from '../../hooks/translationHooks'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { useSelectedItemId } from '../../hooks/PMHooks'

export const HomeRecents = ({ onSelectItem, items }) => {
  const placeholders = useTranslatedPlaceholders('home.recent.placeholder_', 3)
  const loading = useSelector(state => isPendingRequestForPMResource(state, PM_API_RESOURCES.RECENTLY_VISITED_COMMENTS))
  const selectedItemId = useSelectedItemId()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedItems = useMemo(() => items, [items.length]) // useMemo to avoid jumpy effect when clicking an element

  const placeholder = useRandomValueFromArray(placeholders)
  if (!memoizedItems.length && !loading) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }

  return (
    <BasicItemList
      items={memoizedItems}
      selectedItemId={selectedItemId}
      onClickItem={onSelectItem}
      loading={loading}
      shouldShowProject
    />
  )
}
