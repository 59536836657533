import styled from 'styled-components'
import _ from 'lodash'
import { useMemo, useCallback, memo, useContext } from 'react'
import { MainLayout } from '../../components/layout/MainLayout'
import { ItemDetailView } from '../ItemDetail/ItemDetail'
import { InboxTopBar } from './InboxTopBar'
import { ITEM_FILTER_SECTION_KEY } from '../../common/src/reducers/filtersKeys'
import { ItemVirtualizedTable } from '../../components/table/ItemVirtualizedTable'
import { useTranslation } from 'react-i18next'
import { DragAndDrop, SupportedTypes } from '../../components/generic/DragAndDrop'
import { getFilenameData } from '../../utils'
import { useCreateNewItem } from '../../common/src/hooks/itemHooks'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { FileContext } from '../../contexts'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

export const Inbox = memo(({ items, selectedItem, selectItem, onRenderLastItem, loading }) => {
  const { t } = useTranslation()

  const placeholdersByType = useMemo(() => {
    return {
      [SupportedTypes.Files]: t('quadrant.drag_and_drop_file_placeholder'),
      [SupportedTypes.Link]: t('quadrant.drag_and_drop_link_placeholder'),
      [SupportedTypes.Text]: t('quadrant.drag_and_drop_text_placeholder'),
    }
  }, [t])

  const createNewItem = useCreateNewItem()
  const { uploadFiles } = useContext(FileContext)

  const onDrop = useCallback(
    async ({ text, link, files, notes }) => {
      const fileName = files && _.head(files)?.name
      const name = text ? text : fileName
      if (!name) {
        return
      }

      const extension = fileName && getFilenameData(fileName)?.extension

      const response = await createNewItem({
        name: name,
        notes,
        link,
        quadrant: 0,
        projectID: undefined,
        eventProps: {
          mode: EVENT_EXTRA.CREATE_ITEM.MODE.MATRIX_DRAG_DROP,
          quadrant: 0,
          fileExtension: extension,
        },
      })
      const { error, payload } = response
      if (!error && !_.isEmpty(files)) {
        uploadFiles({ item: payload, files })
      }
    },
    [createNewItem, uploadFiles]
  )

  const onRowClick = useCallback(
    ({ rowData }) => {
      selectItem(rowData)
    },
    [selectItem]
  )

  const topBar = <InboxTopBar key={'inboxTopBar'} items={items} />

  const placeholder = useMemo(() => {
    return {
      title: t('inbox.placeholder.title'),
      message: t('inbox.placeholder.message'),
    }
  }, [t])

  const main = (
    <DragAndDrop placeholdersByType={placeholdersByType} onDrop={onDrop}>
      <Container>
        <ItemVirtualizedTable
          items={items}
          selectedItem={selectedItem}
          endReached={onRenderLastItem}
          onRowClick={onRowClick}
          filterMode={ITEM_FILTER_SECTION_KEY.INBOX}
          isSortEnabled
          loading={loading}
          placeholder={placeholder}
        />
      </Container>
    </DragAndDrop>
  )

  const itemDetail = <ItemDetailView />
  return <MainLayout topBar={topBar} main={main} rightSide={itemDetail} />
})
