import styled from 'styled-components'
import { forwardRef, useState } from 'react'
import { cn } from '@appfluence/classnames'
import { useMobile } from '../helpers/responsiveHelpers'
import { ShortcutHelp } from './shortcutHelp/ShortcutHelp'
import { SEARCH_KEYS } from '../constants/shortcutKeys'
import { Input } from '@fluentui/react-components'
import { SearchRegular } from '@fluentui/react-icons'

const SShortcutHelp = styled(ShortcutHelp)`
  position: absolute;
  top: 4px;
  right: 4px;
  transition: opacity 0.2s ease;
  box-shadow: -8px 0 5px 0 ${p => p.theme.palette.white};
  opacity: 0;
  &.show {
    opacity: 1;
  }
`

export const SearchBoxWithShortcut = forwardRef(({ className, ...rest }, ref) => {
  const [showHelp, setShowHelp] = useState(true)
  const helpClassName = cn({ show: showHelp }, 'group-hover:opacity-0')
  const isMobile = useMobile()
  const handleFocus = event => {
    rest?.onFocus?.(event)
    setShowHelp(false)
  }
  const handleBlur = event => {
    rest?.onBlur?.(event)
    setShowHelp(true)
  }
  return (
    <div className={cn('group relative', className)}>
      <Input {...rest} ref={ref} onFocus={handleFocus} onBlur={handleBlur} contentBefore={<SearchRegular />} />
      {!isMobile && <SShortcutHelp className={helpClassName} keys={SEARCH_KEYS} />}
    </div>
  )
})
