import React, { memo, useCallback, useMemo } from 'react'
import _ from 'lodash'
import { mergeStyleSets } from '@fluentui/react/lib/Styling'
import { TileStructure } from '../../../components/tile/TileStructure'
import { useNarrowWidth } from '../../../hooks/useNarrowWidth'
import { BlankProjectCard, ProjectCards } from './ProjectCards'
import { PROJECT_TEMPLATE_TYPE } from '../ProjectTemplateType'
import { useTranslation } from 'react-i18next'
import { useCustomProjectTemplates, usePublicProjectTemplateSearch } from '@/queries/projectTemplates'

const classNames = {
  container: 'ProjectTemplateRecommendations-container',
  tile: 'ProjectTemplateRecommendations-tile',
  card: 'ProjectTemplateRecommendations-card',
}

const getClassNames = _.memoize(() => {
  return mergeStyleSets({
    container: [
      classNames.container,
      {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'scroll',
        selectors: {
          [`.${classNames.tile}`]: { marginLeft: 8 },
          [`.${classNames.tile}:first-child`]: { marginLeft: 0 },
        },
      },
    ],
    tile: [
      classNames.tile,
      {
        cursor: 'pointer',
      },
    ],
    card: [
      classNames.card,
      {
        width: '100%',
        height: '100%',
      },
    ],
  })
})

export const ProjectTemplateRecommendations = memo(
  ({ type = PROJECT_TEMPLATE_TYPE.PUBLIC, maxCards = 4, onSelect }) => {
    const narrow = useNarrowWidth()
    const classNames = getClassNames(narrow)
    const { t } = useTranslation()

    const createOnSelect = useCallback(
      template => () => {
        onSelect({ type, template })
      },
      [onSelect, type]
    )
    const onBlank = useCallback(() => {
      onSelect({ type: PROJECT_TEMPLATE_TYPE.BLANK })
    }, [onSelect])

    const { data: customTemplates } = useCustomProjectTemplates()
    const { data: publicTemplates } = usePublicProjectTemplateSearch({ text: '' })

    const customTemplatesList = customTemplates?.sort((a, b) => a.name.localeCompare(b.name))
    const publicTemplatesList = publicTemplates?.sort((a, b) => b.downloads - a.downloads)

    const templates = useMemo(() => {
      const list = type === PROJECT_TEMPLATE_TYPE.CUSTOM ? customTemplatesList : publicTemplatesList
      return list?.slice(0, maxCards)
    }, [type, maxCards, customTemplatesList, publicTemplatesList])

    return (
      <div className={classNames.container}>
        <TileStructure name={t('project_template.blank_project')} className={classNames.tile} onClick={onBlank}>
          <BlankProjectCard className={classNames.card} />
        </TileStructure>
        {templates?.map(t => {
          return (
            <TileStructure key={t.id} name={t.name} className={classNames.tile} onClick={createOnSelect(t)}>
              <ProjectCards type={type} template={t} className={classNames.card} size={narrow ? 64 : 104} />
            </TileStructure>
          )
        })}
      </div>
    )
  }
)
