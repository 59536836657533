import React, { useCallback, useContext, useDeferredValue, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '../../common/src/helpers'
import { getNextInboxPlusPageIfNecessary } from '../../common/src/actions/combinedAPI'
import { useSelectedItem } from '../../hooks/PMHooks'
import { PM_API_RESOURCES } from '../../common/src/constants'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { Inbox } from './Inbox'
import { ViewSizeContext } from '../../contexts'
import { nextQueryStringRequestForPMResource } from '../../common/src/selectors/requestStateSelectors'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { useHistory } from 'react-router'
import { useApplyFiltersToItems } from '../../hooks/itemHooks'

export const InboxView = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const needMorePages = useSelector(state => nextQueryStringRequestForPMResource(state, PM_API_RESOURCES.INBOX_PLUS))
  const inboxItemsSync = useSelector(stateHelper.getInboxPlusItems)
  const inboxItems = useDeferredValue(inboxItemsSync)
  const filteredItems = useApplyFiltersToItems(inboxItems, FILTER_REDUCER_KEYS.INBOX)
  const items = useMemo(() => filteredItems.toArray(), [filteredItems])
  const { narrowWidth } = useContext(ViewSizeContext)
  const [loading, setLoading] = useState(false)
  const selectedItemSync = useSelectedItem()
  const selectedItem = useDeferredValue(selectedItemSync)

  const getInbox = useCallback(async () => {
    const promise = dispatch(getNextInboxPlusPageIfNecessary())
    setLoading(true)
    await promise
    setLoading(false)
  }, [dispatch])

  useEffect(() => {
    getInbox()
  }, [getInbox])

  const onClickItem = useCallback(
    item => {
      const itemID = itemHelper.getId(item)
      const path = narrowWidth
        ? getRelativeURLKeepingQuerySearch.itemViewInAppForID(itemID)
        : getRelativeURLKeepingQuerySearch.inboxWithItem(itemID)
      history.push(path)
    },
    [narrowWidth, history]
  )

  const onRenderLastItem = useCallback(() => {
    if (!needMorePages) {
      return
    }
    getInbox()
  }, [getInbox, needMorePages])
  return (
    <Inbox
      items={items}
      selectedItem={selectedItem}
      selectItem={onClickItem}
      onRenderLastItem={onRenderLastItem}
      loading={loading}
    />
  )
}
