import { useMemo, useRef } from 'react'
import { createCancellablePromiseQueue } from '../helpers/promiseHelpers'
import { handleSingleAndDoubleClick } from '../helpers/eventHelpers'

export const useSingleAndDoubleClick = (onClick, onDoubleClick, { delay = 300, prevent = true } = {}) => {
  const cancellablePromiseQueueRef = useRef(createCancellablePromiseQueue())
  const handleClick = useMemo(
    () => handleSingleAndDoubleClick(cancellablePromiseQueueRef.current, onClick, onDoubleClick, { delay, prevent }),
    [delay, onClick, onDoubleClick, prevent]
  )
  return handleClick
}
