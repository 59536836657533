export const createDelayedPromise = n => new Promise(resolve => setTimeout(resolve, n))

export const createCancellablePromise = promise => {
  const cancellablePromiseObj = {
    isCanceled: false,
    cancel() {
      this.isCanceled = true
    },
  }

  const cancellablePromise = new Promise(resolve => {
    promise.then(value => {
      resolve({ isCanceled: cancellablePromiseObj.isCanceled, value })
    })
  })

  cancellablePromiseObj.promise = cancellablePromise
  return cancellablePromiseObj
}

export const createCancellablePromiseQueue = () => ({
  pendingPromise: [],
  append(promise) {
    this.pendingPromise = [...this.pendingPromise, promise]
  },
  remove(promise) {
    this.pendingPromise = this.pendingPromise.filter(p => p !== promise)
  },
  clear() {
    this.pendingPromise.forEach(p => p.cancel())
    this.pendingPromise = []
  },
  count() {
    return this.pendingPromise.length
  },
  new(promise) {
    const cPromise = createCancellablePromise(promise)
    this.append(cPromise)

    return cPromise
  },
})
