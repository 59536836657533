import { type ReactNode, useEffect, useState } from 'react'
import { EditorContent, type FocusPosition, useEditor } from '@tiptap/react'
import { Document } from '@tiptap/extension-document'
import { Paragraph } from '@tiptap/extension-paragraph'
import { History } from '@tiptap/extension-history'
import { Text } from '@tiptap/extension-text'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Link } from './plugins/Link'
import './TipTapNotesStyles.scss'
import { PM2TipTap, TipTap2PM } from './converter'
import { TipTapToolbar } from './TipTapToolbar'
import { cn } from '@appfluence/classnames'
import { CustomBulletList, CustomListItem, CustomTaskItem, CustomTaskList } from '../TipTapCustomExtensions'

type TipTapNotesProps = {
  // Value for uncontrolled mode
  initialValue?: string
  // Value for controlled mode
  value?: string
  onUpdate: (value: string) => void
  placeholder?: string
  readOnly?: boolean
  autofocus?: FocusPosition
  containerClassName?: string
  toolbarClassName?: string
  contentClassName?: string
  editorClassName?: string
  additionalToolbarButtons?: ReactNode
  header?: ReactNode
  'data-testid'?: string
}

export const TipTapNotes = ({
  initialValue,
  value,
  onUpdate,
  placeholder,
  readOnly = false,
  autofocus,
  containerClassName,
  toolbarClassName,
  contentClassName,
  editorClassName,
  additionalToolbarButtons,
  header,
  'data-testid': dataTestId,
}: TipTapNotesProps) => {
  const [initialContent] = useState(() => PM2TipTap(initialValue ?? value))

  const editor = useEditor({
    editorProps: {
      attributes: {
        ...(editorClassName && { class: editorClassName }),
        ...(dataTestId && { 'data-testid': dataTestId }),
      },
    },
    extensions: [
      Document,
      Paragraph,
      Text,
      History,
      CustomBulletList,
      CustomListItem,
      CustomTaskList,
      CustomTaskItem,
      Placeholder.configure({ placeholder }),
      Link,
    ],
    content: {
      type: 'doc',
      content: initialContent,
    },
    onUpdate: ({ editor, transaction }) => {
      if (transaction.docChanged) {
        const text = TipTap2PM(editor.getJSON().content ?? [])
        onUpdate(text)
      }
    },
    editable: !readOnly,
  })

  // We don't use autofocus editor prop because it doesn't let us set the scrollIntoView option to false
  useEffect(() => {
    if (editor && autofocus) {
      editor.commands.focus(autofocus, { scrollIntoView: false })
    }
  }, [editor, autofocus])

  useEffect(() => {
    if (editor && value !== undefined) {
      const currentPMValue = TipTap2PM(editor.getJSON().content ?? [])
      if (currentPMValue === value) {
        return
      }
      const selection = editor.state.selection
      // Set the content of the editor to the new value preserving the selection
      editor
        .chain()
        .setContent({ type: 'doc', content: PM2TipTap(value) })
        .setTextSelection(selection)
        .run()
    }
  }, [editor, value])

  useEffect(() => {
    if (editor) {
      editor.setOptions({ editable: !readOnly })
    }
  }, [editor, readOnly])

  return (
    <div className={cn('flex flex-col', containerClassName)}>
      {!readOnly && (
        <TipTapToolbar editor={editor} className={toolbarClassName} additionalButtons={additionalToolbarButtons} />
      )}
      {header}
      {editor ? (
        <EditorContent
          className={cn(
            readOnly && 'mt-2',
            'w-full flex-1 text-pm-black [&_*]:[overflow-wrap:anywhere]',
            contentClassName
          )}
          editor={editor}
        />
      ) : (
        // Simulate placeholder when (editor === null) at first render
        <div className={cn(readOnly && 'mt-2', 'ProseMirrorPlaceholder box-border w-full flex-1 px-4')}>
          {placeholder}
        </div>
      )}
    </div>
  )
}
