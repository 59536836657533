import React, { Suspense } from 'react'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { useSelector } from 'react-redux'
import { resourceRequestSelectors } from '../../common/src/selectors'
import { Loading } from '../../components/basic/Loading'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'

const styles = {
  image: {
    maxHeight: 200,
  },
}

export const ResourceRedirectView = ({ match }) => {
  const didFetchProjects = useSelector(resourceRequestSelectors.didFetProjectSummaries)
  React.useEffect(() => {
    if (didFetchProjects) {
      const { resource, id } = match.params || {}
      if (!resource || !id) {
        return
      }
      // needs to be executed after a timeout, since routing logic
      // must be ready to properly process redirection
      setTimeout(() => {
        linkSubject.next({
          urlData: uriDataCreator(1, resource, id),
          source: SOURCES.REDIRECT_VIEW,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didFetchProjects])
  return (
    <Suspense fallback={<Loading />}>
      <LazyIViewPlaceholder title="Redirecting..." styles={styles} type={IVIEW_PLACEHOLDER_TYPES.REDIRECT} />
    </Suspense>
  )
}
