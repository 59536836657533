import _ from 'lodash'
import { useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '../../common/src/helpers'
import { useMemo } from 'react'
import { checkOwnershipByMode } from './checkOwnershipByMode'
import { datefns } from '../../utils/datefns'
import { getYYYYMMDD, dateToTimestampInSeconds } from '../../common/src/helpers/dateHelper'

export const getTimeInterval = now => {
  const end = _.flow([datefns.add({ days: 7 }), datefns.endOfDay])(now)
  const start = _.flow([datefns.add({ months: -1 }), datefns.startOfDay])(now)
  return [start, end]
}

const useItemsInInterval = (interval, mode) => {
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const meEmail = useSelector(stateHelper.getMeEmail)
  const [start, end] = _.map(interval, d => dateToTimestampInSeconds(d))
  const inInterval = x => _.inRange(x, start, end)
  const checkOwnership = checkOwnershipByMode[mode]
  return allItems.filter(i => {
    const due = itemHelper.getDueTimestamp(i)
    return checkOwnership(i, meEmail) && inInterval(due) && itemHelper.getState(i) === itemHelper.STATE.UNFINISHED
  })
}

export const useAgendaItems = mode => {
  const nowTrigger = getYYYYMMDD(new Date())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const now = useMemo(() => new Date(), [nowTrigger])
  const interval = useMemo(() => getTimeInterval(now), [now])
  return { items: useItemsInInterval(interval, mode), now, interval }
}
