import { memo, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { itemHelper, userHelper } from '../../common/src/helpers'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useOrganizedUsersOptions } from '../../hooks/useUsersOption'
import { UsersPanel } from '../users/panels/UsersPanel'
import { inviteGroupToItemWithId, inviteToItemWithId, uninviteToItemWithId } from '../../common/src/actions/itemsAPI'
import { useUsersMenuProps } from '../../hooks/usersMenuPropsHooks'
import { useTranslation } from 'react-i18next'
import { useToastController } from '@fluentui/react-components'
import { FluentToast } from '../toast/FluentToast'

export const FollowersPanel = memo(({ serverID, project, item, onDismiss, ...rest }) => {
  const dispatch = useDispatch()
  const { dispatchToast } = useToastController()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const followers = useMemo(() => {
    const list = itemHelper.getFollowers(item)
    return list ? list.toArray() : []
  }, [item])

  const options = useOrganizedUsersOptions({
    project,
    minusUsers: followers,
  })

  const onReceiveFollowers = useCallback(
    apiAction => {
      if (apiAction.error && apiAction.payload.status === 403) {
        const email = apiAction.meta.email
        //show toast
        dispatchToast(<FluentToast>{t('item_detail.followers_invite_error', { email })}</FluentToast>, {
          timeout: -1,
          intent: 'error',
        })
      }
      setLoading(false)
    },
    [setLoading, dispatchToast, t]
  )

  const onSelectFollower = useCallback(
    user => {
      const email = user ? userHelper.getEmail(user) : ''
      if (_.isEmpty(email)) {
        return
      }
      setLoading(true)

      dispatch(inviteToItemWithId(serverID, email)).then(onReceiveFollowers)
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.ADD_FOLLOWER))
    },
    [dispatch, onReceiveFollowers, serverID]
  )

  const onSelectGroup = useCallback(
    /**
     * @typedef {import("@/types/userGroup").UserGroup} UserGroup
     * @param {UserGroup} group
     */
    async group => {
      const emails = group.memberships.map(membership => membership.user.email)

      if (_.isEmpty(emails)) return

      setLoading(true)
      await dispatch(inviteGroupToItemWithId(serverID, group.id))
      setLoading(false)

      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.ADD_FOLLOWER_GROUP))
    },
    [dispatch, serverID]
  )

  const onRemoveFollower = useCallback(
    user => {
      const email = user ? userHelper.getEmail(user) : ''
      if (_.isEmpty(email)) {
        return
      }
      setLoading(true)

      dispatch(uninviteToItemWithId(serverID, email)).then(onReceiveFollowers)
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.REMOVE_FOLLOWER))
    },
    [dispatch, onReceiveFollowers, serverID]
  )

  const menuProps = useUsersMenuProps({ users: followers, object: item })

  return (
    <UsersPanel
      headerText={t('item_detail.followers_panel_header')}
      onDismiss={onDismiss}
      users={followers}
      onSelectGroup={onSelectGroup}
      options={options}
      onSelectUser={onSelectFollower}
      onRemoveUser={onRemoveFollower}
      usersHeader={t('users.followed_by')}
      textFieldPlaceholder={t('item_detail.followers_panel_text_field_placeholder')}
      invitationIconProps={{ title: t('item_detail.add_follower_tooltip') }}
      loading={loading}
      menuProps={menuProps}
      removeText={t('users.press_to_remove')}
      {...rest}
    />
  )
})
