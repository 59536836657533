import React from 'react'
import styled from 'styled-components'
import { UserCollectionCell } from './UserCollectionCell'
import { ExtraUserCollectionCell } from './ExtraUserCollectionCell'
import { cn } from '@appfluence/classnames'

const ExtraCell = styled(ExtraUserCollectionCell)`
  margin: 0 0 0 -4px;
`

export const UsersCollection = ({
  users = [],
  max = 2,
  personaProps = {},
  showAddCell = true,
  addButtonProps,
  onClick,
  readOnly = false,
  containerRef,
  onClickUser,
  tooltipText,
  className,
  disabled,
  ...rest
}) => {
  const adjustMax = users.length === max + 1 ? users.length : max
  const finalUsers = adjustMax < 0 ? users : users.slice(0, adjustMax)
  const elements =
    finalUsers.map((user, k) => {
      return (
        <UserCollectionCell
          key={k}
          className={cn('-ml-1 border-2 border-solid border-pm-white')}
          user={user}
          personaProps={personaProps}
          readOnly={readOnly}
          highlightAdmin
          onClick={onClickUser ? () => onClickUser(user) : undefined}
          tooltipText={tooltipText}
          hidePersonaDetails
        />
      )
    }) || []
  const remainder = users.length - elements.length

  return (
    <button
      className={cn(
        'flex border-none bg-transparent px-0',
        !readOnly && !disabled && 'cursor-pointer',
        disabled && 'brightness-150 dark:brightness-50',
        className
      )}
      ref={containerRef}
      onClick={readOnly ? undefined : onClick}
      disabled={disabled}
      {...rest}
    >
      <div className="flex flex-wrap items-center pl-0.5">
        {elements}
        {remainder > 0 && <ExtraCell text={`+${remainder}`} size={personaProps.avatar?.size} {...addButtonProps} />}
        {showAddCell && !readOnly && (
          <ExtraCell iconName={'Add'} size={personaProps.avatar?.size} disabled={disabled} {...addButtonProps} />
        )}
      </div>
    </button>
  )
}
