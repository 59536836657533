import ReconnectingWebSocket from 'reconnecting-websocket'
import type { Comment } from '@/types/comment'
import { useQueryClient } from '@tanstack/react-query'
import { useRef } from 'react'
import { useMe } from '../../common/src/hooks/usersHooks'
import { userHelper } from '../../common/src/helpers'
import { type CommentsInItemData, useSendCommentStatus } from '../../queries/comments'
import { useStore } from 'react-redux'
import { appendQueryParamsToURL } from '../../common/src/helpers/requestHelper'
import useDebouncedEffect from '../../hooks/useDebouncedEffect'

type RWS = ReconnectingWebSocket

const WS_READ_RECEIPT = JSON.stringify({ type: 'read_receipt' })

export const useChatWebsocket = (itemID: number, { isVisible = true }) => {
  const queryClient = useQueryClient()
  const websocketRef = useRef<RWS | null>(null)
  const me = useMe()
  const sendCommentMutationState = useSendCommentStatus(itemID)
  const store = useStore()

  useDebouncedEffect(
    () => {
      if (isVisible) {
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
        const url = `${protocol}//${window.location.host}/realtime/comments/item/${itemID}/`
        websocketRef.current = new ReconnectingWebSocket(appendQueryParamsToURL(url)(store.getState()))
        websocketRef.current.addEventListener('message', e => {
          const newComment = JSON.parse(e.data) as Comment
          const isFromMe = newComment.author === userHelper.getURI(me)
          if (
            isFromMe &&
            sendCommentMutationState.some(
              mutation => mutation.variables?.commentText === newComment.text && mutation.status === 'pending'
            )
          ) {
            return
          }
          queryClient.setQueryData<CommentsInItemData>(['comments', itemID], oldData => {
            if (!oldData) return oldData
            const commentIsAlreadyStored = oldData.pages.some(page => page.objects.some(c => c.id === newComment.id))
            if (!commentIsAlreadyStored) {
              return {
                pages: [{ objects: [newComment] }, ...oldData.pages],
                pageParams: ['', ...oldData.pageParams],
              }
            }
            const newPages = oldData.pages.map(page => ({
              ...page,
              objects: page.objects.map(oldComment => (oldComment.id === newComment.id ? newComment : oldComment)),
            }))
            return { pages: newPages, pageParams: oldData.pageParams }
          })
          if (!isFromMe && !newComment.is_read) {
            websocketRef.current?.send(WS_READ_RECEIPT)
          }
        })
      }
      return () => {
        websocketRef.current?.close()
      }
    },
    100,
    [itemID, me, queryClient, sendCommentMutationState, isVisible, store]
  )

  return websocketRef.current
}
