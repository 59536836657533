import { useCallback, useContext, useRef, useState } from 'react'
import { cn } from '@appfluence/classnames'
import { MobileNavBarButtonContainer as ButtonContainer } from './MobileNavBarButton'
import { ICON_NAMES } from '../../common/src/msIcons'
import { NavPanel } from './NavPanel'
import { ContextualMenu, DirectionalHint, PanelType } from '@fluentui/react'
import { BubbleButton } from '../buttons/BubbleButton'
import { useAttentionNeeded } from '../../hooks/useAttentionNeeded'
import { useExtendedHistory } from '../../hooks/useExtendedHistory'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useSharingRequestsForMe } from '../../common/src/hooks/sharingRequestsHooks'
import { CUSTOM_ICONS } from '../../icons/customIcons'
import { getNavOnClick, Center, Bar, Title } from './commonMobileNavBar'
import { InvitationsModalContext } from '../../contexts'
import { openExternalLink } from '../../utils/externalLinkHandler'
import { SERVER_URLS } from '../../common/src/constants'
import { useDispatch } from 'react-redux'
import { dispatchEvent, AMPLITUDE_ACTION_TYPES, EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useTranslation } from 'react-i18next'
import { LeftPanelUserButton } from '../leftPanel/LeftPanelUserButton'
import { openPurchaseExperienceAction } from '../../utils/teams'
import { isElectronApp } from '../../integrations/electron'
import { useFastProjectsMenu } from '../leftPanel/useFastProjectsMenu'
import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components'
import { Add } from '../BundledIcons'
import { MenuItemLink } from '../link/MenuItemLink'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { useCenterTitle } from '../managers/ParamsManager'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

export const TeamsNavBarComponent = () => {
  const createItem = useCreateItemModal()
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()
  const invitationsModalContext = useContext(InvitationsModalContext)
  const invitationsCount = useSharingRequestsForMe().size
  const { count: alertCount } = useAttentionNeeded()
  const { history, count: historyCount /* canGoForward */ } = useExtendedHistory()
  const onClickMenu = useCallback(() => setIsOpen(true), [])
  const onClickUpgrade = useCallback(
    () => dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.TEAMS_NAV_BAR)),
    [dispatch]
  )
  const onDismiss = useCallback(() => setIsOpen(false), [])
  const titleData = useCenterTitle()
  const center = titleData ? <Title {...titleData} /> : undefined
  const invitationCN = cn({ pm_hidden: invitationsCount === 0 })
  const upgradeCN = cn({ pm_hidden: !shouldDisplayUpgrade })
  const projectsButtonRef = useRef()
  const [fastProjectsMenuOpen, setFastProjectsMenuOpen] = useState(false)

  const getOnClick = getNavOnClick(history)
  const onClickFeed = getOnClick(getRelativeURLKeepingQuerySearch.globalFeedApp)
  const onClickHome = getOnClick(() => getRelativeURLKeepingQuerySearch.homeSection('alerts'))
  const onClickSearch = getOnClick(getRelativeURLKeepingQuerySearch.searchApp)
  const onClickWebinars = () => {
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_WEBINARS_LINK))
    openExternalLink(`${SERVER_URLS.WEBINARS}${isElectronApp() ? '?embedded=1' : ''}`)
  }
  const onClickProjects = () => setFastProjectsMenuOpen(prev => !prev)
  const onClickInvitations = () => invitationsModalContext.setIsOpen(true)

  const onClickBack = () => {
    history.goBack()
  }
  const backButtonDisabled = !historyCount

  // TODO
  // const onClickForward = () => {
  //   history.goForward()
  // }
  // const forwardButtonDisabled = !canGoForward

  return (
    <Bar className="app-drag">
      <ButtonContainer onClick={onClickMenu} className="ml-[env(titlebar-area-x)]">
        <BubbleButton iconName={ICON_NAMES.CollapseMenu} n={0} size={16} />
      </ButtonContainer>
      <ButtonContainer
        onClick={backButtonDisabled ? undefined : onClickBack}
        tooltip={t('general.go_back')}
        disabled={backButtonDisabled}
      >
        <BubbleButton iconName={ICON_NAMES.ChromeBack} n={0} size={16} disabled={backButtonDisabled} />
      </ButtonContainer>
      {/*TODO*/}
      {/*<ButtonContainer*/}
      {/*  onClick={forwardButtonDisabled ? undefined : onClickForward}*/}
      {/*  tooltip={t('general.go_forward')}*/}
      {/*  disabled={forwardButtonDisabled}*/}
      {/*>*/}
      {/*  <BubbleButton iconName="ChromeBackMirrored" n={0} size={16} disabled={forwardButtonDisabled} />*/}
      {/*</ButtonContainer>*/}
      <ButtonContainer onClick={onClickUpgrade} tooltip={t('left_panel.upgrade_tooltip')} className={upgradeCN}>
        <BubbleButton iconName={ICON_NAMES.Ribbon} n={' '} size={16} />
      </ButtonContainer>
      <ButtonContainer onClick={onClickHome} tooltip={t('left_panel.home_button_tooltip')}>
        <BubbleButton iconName={ICON_NAMES.Home} n={alertCount + invitationsCount} size={16} />
      </ButtonContainer>
      <ButtonContainer
        onClick={onClickInvitations}
        className={invitationCN}
        tooltip={t('left_panel.pending_invitation_button_tooltip')}
      >
        <BubbleButton iconName={ICON_NAMES.MailForwardMirrored} n={invitationsCount} size={16} />
      </ButtonContainer>
      <div ref={projectsButtonRef}>
        <ButtonContainer onClick={onClickProjects} tooltip={t('left_panel.projects_button_tooltip')}>
          <BubbleButton iconName={CUSTOM_ICONS.QUADRANTS} n={0} size={16} />
        </ButtonContainer>
      </div>
      {fastProjectsMenuOpen && (
        <FastProjectsMenu
          target={projectsButtonRef.current}
          hidden={!fastProjectsMenuOpen}
          onDismiss={() => setFastProjectsMenuOpen(false)}
        />
      )}
      <ButtonContainer onClick={onClickFeed} tooltip={t('left_panel.feed_button_tooltip')}>
        <BubbleButton iconName={ICON_NAMES.ActivityFeed} n={0} size={16} />
      </ButtonContainer>
      <ButtonContainer onClick={onClickSearch} tooltip={t('left_panel.search_button_tooltip')}>
        <BubbleButton iconName={ICON_NAMES.Search} n={0} size={16} />
      </ButtonContainer>
      <ButtonContainer onClick={onClickWebinars} tooltip={t('left_panel.webinars_tooltip')}>
        <BubbleButton iconName={ICON_NAMES.JoinOnlineMeeting} n={0} size={16} />
      </ButtonContainer>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button icon={<Add />} appearance="transparent" className="app-no-drag !text-white hover:!text-neutral-200">
            {t('left_panel.create_name')}
          </Button>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItemLink
              href={getRelativeURLKeepingQuerySearch.newProjectApp()}
              onClick={() => {
                dispatch(
                  dispatchEvent(AMPLITUDE_ACTION_TYPES.CREATE_PROJECT, {
                    mode: EVENT_EXTRA.CREATE_PROJECT.MODE.APP_TOP_BAR,
                  })
                )
              }}
            >
              {t('general.project')}
            </MenuItemLink>
            <MenuItem
              onClick={() => {
                createItem({
                  mode: EVENT_EXTRA.CREATE_ITEM.MODE.APP_TOP_BAR,
                  displayProjectSelector: true,
                })
              }}
            >
              {t('general.item')}
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
      <Center>{center}</Center>
      <div className="mr-[max(0px,calc(100vw-env(titlebar-area-x)-env(titlebar-area-width)))] self-center">
        <LeftPanelUserButton className="app-no-drag" />
      </div>
      <NavPanel isOpen={isOpen} onDismiss={onDismiss} isLightDismiss type={PanelType.smallFixedNear} />
    </Bar>
  )
}

const FastProjectsMenu = ({ target, hidden, onDismiss }) => {
  const menuProps = useFastProjectsMenu()
  return (
    <ContextualMenu
      {...menuProps}
      target={target}
      hidden={hidden}
      onDismiss={onDismiss}
      directionalHint={DirectionalHint.bottomLeftEdge}
    />
  )
}
