import { createAction } from 'redux-api-middleware'
import { appendQueryParamsToURL, extendPMHeaders, getCredentialsConfig } from '../helpers/requestHelper'
import { PM_API_RESOURCES, PM_API_URLS } from '../constants'
import { generateActionTypesForPMAPI } from '../helpers/reducerHelper'

export const getConfig = (params = {}) =>
  createAction({
    endpoint: appendQueryParamsToURL(PM_API_URLS.CONFIG, params),
    credentials: getCredentialsConfig(),
    method: 'GET',
    types: generateActionTypesForPMAPI(PM_API_RESOURCES.CONFIG),
    headers: extendPMHeaders(),
  })
