import React, { memo, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { userHelper } from '../../common/src/helpers'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useMe, useUserForEmail } from '../../common/src/hooks/usersHooks'
import { useOrganizedUsersOptions } from '../../hooks/useUsersOption'
import { UserPanel } from '../../components/users/panels/UserPanel'
import { addCollaborator } from '../../common/src/actions/usersAPI'
import { useUsersMenuProps } from '../../hooks/usersMenuPropsHooks'
import { useTranslation } from 'react-i18next'

export const OneOnOneUserSelectionPanel = memo(({ email, headerText, onChange, onDismiss, ...rest }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useUserForEmail(email)
  const me = useMe()
  const minusUsers = useMemo(() => {
    return [user, me]
  }, [user, me])
  const options = useOrganizedUsersOptions({
    minusUsers,
  })

  const inviteUnknownUser = useCallback(
    email => {
      dispatch(addCollaborator(email))
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.ADD_COLLABORATOR))
    },
    [dispatch]
  )

  const onSelectUserClosing = useCallback(
    (user, isUnknown) => {
      const email = user ? userHelper.getEmail(user) : ''
      const userID = user ? userHelper.getID(user) : ''
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.SELECT_ONE_ON_ONE_COLLABORATOR))
      onChange(email, isUnknown, userID)

      if (email && isUnknown) {
        inviteUnknownUser(email)
      }

      onDismiss()
    },
    [onChange, onDismiss, dispatch, inviteUnknownUser]
  )

  const menuProps = useUsersMenuProps({ users: user ? [user] : null })

  return (
    <UserPanel
      headerText={headerText || t('one_on_one.user_selection_header')}
      onDismiss={onDismiss}
      user={user}
      options={options}
      onSelectUser={onSelectUserClosing}
      selectedUserHeader={t('one_on_one.user_selection_user_section')}
      textFieldPlaceholder={t('one_on_one.user_selection_text_field_placeholder')}
      invitationIconProps={{ title: t('one_on_one.user_selection_text_field_placeholder') }}
      hiddenClearButton={true}
      menuProps={menuProps}
      {...rest}
    />
  )
})
